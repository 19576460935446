/**
 * events-banner
 */

.events-banner {
  .margin(bottom, big);
  background: @gray--250;
  text-align: center;
  .breadcrumbs {
    display: flex;
    justify-content: center;
    .padding(y, 3rem);
  }
}

.events-banner_items {
  display: inline-flex;
  flex-wrap: nowrap;
}

.events-banner_item {
  .link--none();
  display: flex;
  flex-direction: column;
  justify-content: center;
  .margin(x, small, sm);
  .padding(x, medium);
  .padding(bottom, 3rem);
  border-bottom: 3px solid transparent;
  .h4();
  .margin(y, 0) !important;
  color: @gray--400 !important;
  [class^="icon-"], [class*=" icon-"] {
    .margin(bottom, medium);
    font-size: 2.5rem;
  }
  &:hover, &.hover, &:active {
    border-bottom: 3px solid @primary;
  }
  &.active {
    border-bottom: 3px solid @primary;
    color: @primary !important;
  }
  transition-property: border, color !important;
  transition-duration: @time--medium !important;
  transition-timing-function: @bez--in-out !important;
}

/**
 * events--grid
 */

.events--grid {
  > .row {
    justify-content: center;
    .events--grid_col {
      .col(12);
      .col(4, sm);
      .col(3, lg);
    }
  }
  /*
  .slide_inner,
  > .row .events--grid_col {
    @media @max-sm {
      margin: auto;
      .col--custom(348px);
    }
  }*/
}


.event--grid {
  .block();
  .link--none();
  .text--inverse() !important;
  background: @white;
  text-align: center;
  .event-post_body--promotion & {
    text-align: left;
  }
  .event_inner {
    .block();
    position: relative;
    padding-bottom: 140%;
  }
  @media @max-sm {
    display: block;
    margin: auto;
    max-width: 348px;
  }
  .event-post & {
    .event_inner {
      padding-bottom: 0;
    }
  }
  .event_head {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: @black;
    .event_img {
      height: 100%;
      // object-fit
      img {
        margin: auto;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        object-position: center center;
        font-family: 'object-fit: cover; object-position: center center'; // polyfill
      }
    }
    .event--news& {
      &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(180deg, fade(@black, 0%) 50%, @black 100%);
      }
    }
  }
  .event_body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    .padding(x, 3rem);
    .padding(y, 3rem);
    .event-post & {
      position: relative;
    }
    .event--event& {
      background: @primary;
    }
  }
  .event_date {
    .event--event& {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      background: @primary;
    }
    .event--news& {
      display: inline-block;
      padding: .75rem 1.5rem;
      background: fade(@black, 33%);
      .font-sans--medium();
      font-size: 11px;
      text-transform: uppercase;
    }
  }
  .event_date_inner {
    .row();
    .row-space(none);
    .padding(y, small);
    .padding(x, small);
    .separator {
      top: 2px;
    }
  }
  .event_date_item {
    .row();
    .row-space(none);
    flex-direction: column;
    .font-sans--medium();
    font-size: 10px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    .event-post & {
      .row-space(micro);
      font-size: 12px;
    }
    span:first-child {
      font-size: 20px;
      .event-post & {
        font-size: 25px;
      }
    }
  }
  .event_img {
    img {
      .responsive();
      .full();
    }
  }
  .event_title {
    .event--event& {
      .font-sans--medium();
    }
    .event--news& {
      .font-sans--regular();
      min-height: 4.8rem;
    }
    font-size: 1.4rem;
    text-transform: none;
  }
}

.event-post {
  .event_head {
    position: relative;
    .event_img {
      padding-bottom: 0 !important;
    }
  }
}

/**
 * .slider--events
 */

.slider--events {
  .block();
  .events--grid& {
    .margin(bottom, giant);
    .categories-home + .container & {
      .margin(bottom, none);
    }
    .slides {
      padding-bottom: 2rem;
    }
    .slides_inner {
      @media @max-sm {
        .row-space(small);
      }
    }
    .slide {
      .col(12);
      .col(4, sm);
      .col(3, lg);
    }
  }
}

/**
 * event-post
 */

.event-post {
}

.event-post_head {
  .padding(y, medium);
  text-align: center;
}

.event-post_body {
  .padding(bottom, medium);
}

.event-post_foot {
  position: relative;
  .padding(bottom, huge);
}

.event-post_date {
  .font-sans--medium();
  font-size: 11px;
  text-transform: uppercase;
  .muted();
}

.event-post_img {
}

.event-post_content {
  .padding(top, big);
  line-height: 1.8;
  h1, h2, h3 {
    .h2();
  }
  h4, h5, h6 {
    .h5();
  }
  img {
    display: block;
    .margin(y, giant);
    .margin(x, auto);
    .responsive();
  }
}

.event-post_info {
  position: relative;
  font-size: 1.6rem;
  line-height: 1.4;
  .event-post_body--promotion & {
    .font-sans--regular();
    font-size: 1.9rem;
  }
}

.event-post_info_date {
  .font-sans--light();
  font-size: 1.6rem;
  line-height: 1.4;
}
