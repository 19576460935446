//////////////////////
// size
//////////////////////

.font--medium {
  font-size: 1.4rem;
  line-height: 1.6;
}

.font--big {
  font-size: 1.5rem;
  line-height: 1.6;
}

//////////////////////
// spacing
//////////////////////

.spacing--small {
  .margin(top, small) !important;
  .margin(bottom, small) !important;
  .margin(y, layout) !important;
}

.spacing--medium {
  .margin(top, small) !important;
  .margin(bottom, medium) !important;
  .margin(y, layout) !important;
}

.spacing--big {
  .margin(top, medium) !important;
  .margin(bottom, 4rem) !important;
  .margin(y, layout) !important;
}

//////////////////////
// headers
//////////////////////

h1, .h1 {
  .spacing--medium();
  .font-sans--medium();
  font-size: 2.2rem;
  color: @black;
  line-height: 1.2;
  letter-spacing: .05em;
  text-transform: uppercase;
}

h2, .h2 {
  .spacing--medium();
  .font-sans--medium();
  font-size: 1.8rem;
  color: @black;
  line-height: 1.2;
  letter-spacing: .05em;
  text-transform: uppercase;
}

h3, .h3 {
  .spacing--medium();
  .font-sans--light();
  font-size: 2.2rem;
  color: @gray--500;
  line-height: 1.4;
  letter-spacing: 0;
  text-transform: none;
}

h4, .h4 {
  .spacing--small();
  .font-sans--medium();
  font-size: 1.6rem;
  color: @gray--500;
  line-height: 1.2;
  letter-spacing: .05em;
  text-transform: uppercase;
}

h5, .h5 {
  .spacing--small();
  .font-sans--medium();
  font-size: 14px;
  color: @gray--750;
  line-height: 1.2;
  letter-spacing: .05em;
  text-transform: uppercase;
}

h6, .h6 {
  .spacing--small();
  .font-sans--regular();
  font-size: 13px;
  color: @black;
  line-height: 1.4;
  letter-spacing: 0;
  text-transform: uppercase;
  &.medium {
    .font-sans--medium();
  }
}

.h7 {
  .spacing--medium();
  .font-sans--light();
  font-size: 1.8rem;
  color: @gray--750;
  line-height: 1.4;
  letter-spacing: 0;
  text-transform: none;
}

// additional headers here (ex: .h7)

// special

.header--line {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  .margin(bottom, big) !important;
  .padding(bottom, 3rem);
  // line
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: @gray--250;
  }
  // spacing
  > * + * {
    .margin(left, small);
  }
}

.header--block {
  /*
  .spacing--giant();
  .padding(y, small);
  .padding(x, medium);
  background: @gray--250;
  text-align: center;
  */
}

//////////////////////
// text
//////////////////////

p {
  .margin(y, layout) !important;
  .margin(bottom, medium);
}

strong {
  .font-sans--medium();
}

small {
  font-size: .8em;
}

.muted {
  opacity: .5;
}

// code

/*
code {
  margin: 0 .05em;
  padding: .05em .05em;
  background: fade(@black, 6%);
  .font-monospace();
  font-size: .9em;
  color: @gray--750;
  .table-stripe tbody tr:nth-of-type(odd) & {
    background: fade(@white, 18%);
  }
}

pre {
  overflow-x: hidden;
  overflow-y: auto;
  .overflow-style();
  .margin(y, layout) !important;
  .margin(y, medium);
  width: 100%;
  max-height: 30rem;
  line-height: 0;
  code {
    .padding(all, medium) !important;
    width: 100%;
    border-radius: .75rem;;
    border-width: 0;
    border-style: solid;
    border-color: @gray--250;
    background: @gray--250 !important;
    font-size: 12px;
    color: @gray--750;
    line-height: 1.6;
  }
}
*/

// .text--inverse

.text--inverse {
  color: @text--inverse;
  h1, h2, h3, h4, h5, h6 {
    color: @text--inverse;
  }
}

//////////////////////
// ul ol
//////////////////////

ul,
ol {
  .margin(top, none);
  .margin(bottom, small);
  padding-left: 2rem;
  > li {
    .margin(bottom, tiny);
  }
  > ul,
  > ol {
    .margin(y, small);
  }
}

ul {
  list-style: disc;
  ul {
    list-style: circle;
  }
}

ol {
  list-style-type: decimal;
  ol {
    list-style-type: lower-alpha;
  }
}

// .list-reset
// use this mixin to reset styles on xtend ul.list ul.slides_inner etc..

.list-reset() {
  cursor: default;
  list-style: none;
  margin: 0;
  padding: 0;
  > * {
    margin: 0;
  }
}

//////////////////////
// dl
//////////////////////

dl {
  .margin(bottom, layout);
  .margin(bottom, small);
  .dl--block();
  line-height: 1.6;
  dt {
    .font-sans--medium();
  }
  dd {
    .font-sans--light();
  }
}

// .dl--block

.dl--block {
  .margin-remove(top, tiny);
  dt {
    .padding(top, small);
  }
  dd {
    .padding(top, tiny);
  }
}

// .dl--line

.dl--line {
  .margin-remove(top, tiny);
  dt {
    .padding(top, tiny);
  }
  dd {
    .padding(left, small);
    .padding(top, tiny);
  }
}

// .dl--horizontal

.dl--horizontal {
  .margin-remove(top, tiny);
  text-align: left;
  dt {
    .padding(top, tiny);
    .padding(right, small);
    float: left;
    clear: left;
    width: 80px;
  }
  dd {
    .padding(left, small);
    .padding(top, tiny);
    margin-left: 80px;
  }
}
