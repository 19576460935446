/**
 * header
 */

.header {
  .block();
  background: @white;
  @media @max-md {
    background: @primary;
    color: @white;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: @gray--250;
    @media @max-md {
      display: none;
    }
  }
}

.header_inner {
  > .row {
    flex-wrap: nowrap;
    @media @max-md {
      .row-space(none);
    }
  }
}

.header_logo {
  .link--none();
  position: relative;
  z-index: 2;
  @media @max-md {
    .container();
    .padding(y, 1.5rem);
    width: auto;
  }
  @media @min-md {
    .padding(all, 3rem);
    background: @primary;
  }
  img {
    width: auto;
    @media @max-md {
      height: 24px;
    }
    @media @min-md {
      height: 30px;
    }
  }
}

.header_center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .flex--auto();
  @media @max-md {
    display: none;
  }
}

.header_center_right {
  > .list {
    .list-space(none);
    flex-wrap: nowrap;
    > * {
      .btn();
      .btn--small();
      .btn--text-alt();
      .btn--tall();
      .padding(x, .6em);
      .padding(y, 0.5rem);
      font-size: 1.3rem;
      @media @min-lg {
        .padding(x, 1em);
      }
    }
  }
}

.header_center_left {
  > .list {
    .list-space(none);
    flex-wrap: nowrap;
    > * {
      .btn();
      .btn--text();
      .btn--giant();
      .btn--tall();
      .padding(x, 1.5rem);
      .padding(y, 0.5rem);
      font-size: 1.4rem;
      @media @max-xl {
        .padding(x, 1rem);
      }
      [class^="icon-"], [class*=" icon-"] {
        position: relative;
        top: -3px;
        margin-left: 10px;
        font-size: 4px;
      }
      /*
      span:after {
        content: '';
        position: absolute;
        top: -5px;
        right: -1.5rem;
        width: 1px;
        height: 20px;
        background: rgb(221,221,221);
        @media @max-xl {
          right: -1rem;
        }
      }
      */
    }
  }
}

.header_right {
  > .list {
    flex-wrap: nowrap;
    @media @max-md {
      .list-space(none);
      .btn--text {
        color: @white !important;
      }
    }
    @media @min-md {
      .container();
      width: auto;
      .list-space(tiny);
    }
  }
}

.btn--header-right {
  @media @max-md {
    .btn--primary();
    .btn--huge();
    .btn--narrow();
    .btn--tall();
  }
  @media @min-md {
    .btn--secondary-outline();
    .btn--huge();
    .btn--icon();
  }
}

.btn--menu-mobile {
  @media @min-md {
    display: none;
  }
  .btn--primary();
  .btn--tall();
  .margin(left, small) !important;
  &@{btn--design} {
    border-color: @primary--dark;
    background: @primary--dark;
  }
  &:hover, &.hover, &:active, &.active, &:focus {
    &@{btn--design} {
      border-color: lighten(@primary--dark, @perc--xs);
      background: lighten(@primary--dark, @perc--xs);
    }
  }
}

/**
 * #overlay--menu-mobile
 */

#overlay--menu-mobile {
  .overlay_content {
    .padding(top, 4rem);
    .padding(bottom, 8rem);
  }
}

.overlay--menu-mobile_top {
  .padding(bottom, medium);
  > .list {
    .list-space(none);
    > * {
      .block();
      .btn();
      .btn--text();
      .btn--huge();
    }
  }
}

.overlay--menu-mobile_bottom {
  position: relative;
  .padding(top, medium);
  .overlay--menu-mobile_top & {
    .padding(top, 0);
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, @grad--white);
  }
  > .list {
    .list-space(none);
    > * {
      .block();
      .btn();
      .btn--big();
      .btn--text-alt();
      .muted();
      .overlay--menu-mobile_top & {
        font-size: 1.6rem;
        opacity: 1;
      }
    }
  }
}

/**
 * #drop--minicart
 */

.btn--minicart {
  overflow: visible;
}

.btn--minicart_badge {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  padding: 3px 6px;
  border-radius: 12px;
  background: @primary--dark;
  .font-sans--medium();
  font-size: 12px;
  color: @white;
}

#drop--minicart {
  @media @max-sm {
    .drop_outer--static();
  }
  .drop_content {
    padding: 2rem 3rem;
    @media @min-md {
      width: 35rem;
    }
  }
}

.drop--minicart_item {
  .list();
  .list-space(tiny);
  flex-wrap: nowrap;
  justify-content: space-between;
  > * {
    line-height: 1.2;
  }
}

.drop--minicart_item--big {
  .font-sans--medium();
  text-transform: uppercase;
}

.drop--minicart_action {
  .list();
  .list-space(tiny);
}

/**
 * search
 */

.header {
  .header_search--show {
    display: none;
  }
  &.search_open {
    background: @gray--750;
    @media @max-md {
      padding: 1rem;
      button[type="submit"] {
        display: none;
      }
    }
    .header_search--show {
      display: flex;
      .anim-zoom.active();
    }
    .header_search--hide {
      display: none;
    }
    @media @max-md {
      .header_search--hide--mobile {
        display: none;
      }
    }
  }
}

.header_search--show {
  .btn--header-right {
    color: @white;
    &@{btn--design} {
      border-color: @gray--400;
    }
    &:hover, &.hover, &:active {
      color: @white;
      &@{btn--design} {
        border-color: @gray--400;
      }
    }
    &.active {
      color: @white;
      &@{btn--design} {
        border-color: @gray--400;
      }
    }
  }
  .form-item {
    padding-bottom: 0;
  }
  > form {
    width: 50%;
    @media @max-md {
      width: 100%;
    }
  }
  .form-input {
    padding: 0 .5em;
    background-color: transparent;
    border-color: transparent;
    font-size: 1.4em;
    color: @white;
    &:focus, &.active {
      border-color: transparent;
    }
  }
  // placeholder
  :-ms-input-placeholder {
    color: @white;
  }
  :-moz-placeholder {
    color: @white;
  }
  ::-webkit-input-placeholder {
    color: @white;
  }
  ::-ms-input-placeholder {
    color: @white;
  }
  ::-moz-placeholder {
    color: @white;
  }
  ::placeholder {
    color: @white;
  }
}

.btn--search--close {
  @media @max-md {
    .btn--secondary-outline();
    .btn--huge();
    .btn--icon();
    font-size: 1em;
  }
}

.list--search {
  padding: 0 1rem;
  flex-wrap: nowrap;
  align-items: center;
}

.list--search_icon {
  position: relative;
  top: .1em;
  font-size: 2rem;
  color: @white;
}
