/**
 * xtend extension: form-label-addon
 */

.form-label-addon {
  .link--none();
  position: absolute;
  top: 0;
  right: 0;
  .font-sans--regular();
  font-size: 1.2rem;
  color: @primary;
  line-height: 1.4;
  text-transform: uppercase;
}

/**
 * xtend extension: page-loader
 */

/*
.site_main,
.site_footer {
  visibility: hidden;
  html.preloaded &,
  html.preload-fade & {
    visibility: visible;
  }
}

#page-loader {
  html.preloaded & {
    display: none;
  }
}

#page-loader {
  > * {
    position: fixed !important;
    z-index: @index--sticky + 2;
  }
}
*/

#page-loader {
  display: none;
}

// prevent links color animation on page load

html:not(.preloaded) {
  * {
    .transition-none() !important;
  }
}

/**
 * xtend extension: cookie
 */

.cookie {
  display: none;
  position: fixed;
  z-index: @index--sticky - 1;
  bottom: 2rem;
  left: 1.5rem;
  @media @max-sm {
    right: 1.5rem;
    width: auto;
  }
  @media @min-sm {
    width: 42rem;
  }
  .cookie_inner {
    .text--inverse();
    padding: 2.5rem;
    background: @primary--dark;
    font-size: 12px;
  }
  > .list {
    justify-content: flex-end;
  }
  // animation
  .cookie_inner {
    animation-name: anim-fade--in;
    animation-duration: @time--big;
    animation-timing-function: @bez--in;
    animation-fill-mode: forwards;
  }
  &.out {
    .cookie_inner {
      animation-name: anim-fade--out;
      animation-duration: @time--big;
      animation-timing-function: @bez--in;
      animation-fill-mode: forwards;
    }
  }
}

/**
 * xtend extension: mask
 */

.mask_inner {
  .block();
  position: relative;
  overflow: hidden;
}

.mask_cover {
  pointer-events: none;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  > * {
    width: 100%;
    height: 100%;
    background: @black;
    opacity: 0;
    @media (hover: none) {
      .transform-none() !important;
      opacity: .3 !important;
    }
  }
}

.mask_img {
  position: relative;
  z-index: 2;
}

.mask_img_cover {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: fade(@black, 30%);
}

.mask_content {
  position: relative;
  z-index: 4;
  opacity: 0;
}

/**
 * xtend extension: overlay classHtml
 */

.header_inner, .site_content { // DO NOT APPLY to selector with descendants with position: fixed;
  transition-property: transform;
  transition-duration: @time--medium;
  transition-timing-function: @bez--in-out;
  html.xt-overlay--right & {
    transform: translateX(-5rem);
    transition-property: transform;
    transition-duration: @time--medium;
    transition-timing-function: @bez--in-out;
  }
}

/**
 * xtend extension: googlelocator
 */

.googlelocator {
  display: flex;
  flex-wrap: wrap;
  @media @max-sm {
    flex-flow: column;
  }
  .flex--auto();
  .googlelocator-default & {
    @media @min-sm {
      min-height: 100vh;
    }
  }
}

// .googlelocator_main

.googlelocator_main {
  position: relative;
  .flex--auto();
  min-height: 100%;
  @media @max-sm {
    .sr-only();
    height: 100vh;
    // hide map
    visibility: hidden;
    pointer-events: none;
    position: fixed; // @FIX no absolute or bugs search
    z-index: -1;
    top: -100vh;
    left: -100vw;
    opacity: 0;
  }
  @media @min-sm {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding-left: 35rem;
  }
  @media @min-md {
    padding-left: 40rem;
  }
  @media @min-lg {
    padding-left: 45rem;
  }
  .googlelocator-checkout & {
    // hide map
    visibility: hidden;
    pointer-events: none;
    position: fixed; // @FIX no absolute or bugs search
    z-index: -1;
    top: -100vh;
    left: -100vw;
    opacity: 0;
  }
}

.googlelocator_main_inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.googlelocator_main_map {
  width: 100%;
  height: 100%;
}

// .googlelocator_aside

.googlelocator_aside {
  position: relative;
  z-index: 1;
  .googlelocator-default & {
    @media @min-sm {
      width: 35rem;
      min-height: 70vh;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    }
    @media @min-md {
      width: 40rem;
    }
    @media @min-lg {
      width: 45rem;
    }
  }
  .googlelocator-checkout & {
    width: 100%;
  }
}

.googlelocator_aside_head {
  position: relative;
  .btn-locate-store-outer {
    position: relative;
    width: auto;
  }
  .googlelocator-default & {
    padding: 3rem;
    background-color: @gray--250;
    input {
      padding: 1em 1.2em;
      border-color: @white !important;
    }
    button[type="submit"] {
      padding: 1rem 1.6rem !important;
      font-size: 2rem !important;
    }
  }
  .googlelocator-checkout & {
    padding: 0 !important;
    background-color: transparent !important;
  }
}

.googlelocator_aside_body {
  position: relative;
  padding: 3rem;
  .checkout & {
    padding: 0 !important;
  }
}

// result

.googlelocator_result {
  display: none;
  padding-bottom: 1rem;
  border-bottom: 1px solid @gray--250;
  font-size: 1.3rem;
}

[data-xt-googlelocator-result-initial] {
  display: block;
}

&.found {
  .googlelocator_result {
    display: none;
  }
  [data-xt-googlelocator-result-founds] {
    display: block;
  }
}

&.noplace {
  .googlelocator_result {
    display: none;
  }
  [data-xt-googlelocator-result-noplace] {
    display: block;
  }
}

&.empty {
  .googlelocator_result {
    display: none;
  }
  [data-xt-googlelocator-result-empty] {
    display: block;
  }
}

&.error {
  .googlelocator_result {
    display: none;
  }
  [data-xt-googlelocator-result-error] {
    display: block;
  }
}

// special

.googlelocator_repeat {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 2rem;
}

.googlelocator_locate {
  .link--none();
  > span {
    font-size: 1.8em;
  }
  color: @gray--300;
  &@{btn--design} {
    border-color: @white;
    background: @white;
  }
  &:hover, &.hover, &:active, &.active {
    color: @primary;
    &@{btn--design} {
      border-color: @white;
      background: @white;
    }
  }
}

// .googlelocator_item

.googlelocator_item {
  .list();
  flex-wrap: nowrap;
  height: auto !important;
  align-items: center;
  justify-content: space-between;
  .padding(top, 2rem);
  .padding(bottom, 1rem);
  border-bottom: 1px solid @gray--250;
  font-size: 1.4rem;
  text-transform: uppercase;
  &.active {
    border-bottom: 1px solid @black;
  }
  .form-label {
    padding-left: 4rem !important;
  }
  input[type="radio"]:not(.unstyled) ~ .form-label {
    &:before, &:after {
      top: 50%;
      margin-top: -.8rem;
    }
  }
}

.googlelocator_item_content {
  cursor: pointer;
}

.googlelocator_item_name {
  .block();
  .h5();
}

.googlelocator_item_address {
  .block();
  .font-sans--light();
  font-size: 1.3rem;
  line-height: 1.4;
}

.googlelocator_item_additional {
  .block();
  .font-sans--light();
  font-size: 1.3rem;
  line-height: 1.4;
}

.googlelocator_item_directions {
  .link--none();
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  .font-sans--medium();
  color: #C4C6CA !important;
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  .googlelocator_item_directions_icon {
    font-size: 3rem;
    color: #C4C6CA !important;
    transition-property: color;
    transition-duration: @time--medium;
    transition-timing-function: @bez--in-out;
  }
  &:hover, &.hover, &:active, &.active {
    .googlelocator_item_directions_icon {
      color: @primary !important;
    }
  }
}

// reset infoWindow

.gm-style {
  .gm-style-iw-c {
    box-shadow: none !important;
    border: 0 !important;
    background: transparent !important;
  }
  .gm-style-iw-t {
    &:after {
      display: none !important;
    }
  }
  .gm-style-iw {
    padding: 0 !important;
    overflow: visible !important;
  }
  .gm-style-iw > div {
    overflow: visible !important;
  }
  .gm-style-iw + div {
    display: none !important;
  }
}

// custom infoWindow

@googlelocator-arrow-size: 10px;

.gm-style {
  .googlelocator_item {
    .text--inverse();
    margin-bottom: @googlelocator-arrow-size - 2px;
    .padding(x, 2rem);
    border: 0;
    background: @primary !important;
    color: @white;
    box-shadow: 0 .1rem 1rem fade(@black, @perc--xs), 0 .2rem 2rem fade(@black, @perc--sm);
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -@googlelocator-arrow-size;
      border-left: @googlelocator-arrow-size solid transparent;
      border-right: @googlelocator-arrow-size solid transparent;
      border-top: @googlelocator-arrow-size solid @primary;
    }
  }
  .googlelocator_item_name {
    color: @white !important;
  }
  .googlelocator_item_directions {
    color: @white !important;
    .googlelocator_item_directions_icon {
      color: @white !important;
    }
    &:hover, &.hover, &:active, &.active {
      .googlelocator_item_directions_icon {
        color: @white !important;
      }
    }
  }
}

// custom infoWindow close

.gm-style {
  .gm-style-iw-d + button {
    width: 3rem !important;
    height: 3rem !important;
    background: @primary--dark !important;
    border-bottom-left-radius: 1rem;
    font-size: 25px;
    color: @white !important;
    line-height: 0;
    text-align: center;
    opacity: 1 !important;
    &:before {
      content: '×';
      position: relative;
      top: -2px;
    }
    > * {
      display: none !important;
    }
  }
}
