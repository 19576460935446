/**
 * category
 */

.category {
  .padding(bottom, giant);
}

.category_empty {
  .block();
  .padding(y, giant);
  position: relative;
  background: @white;
  text-align: center;
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgb(221,221,221);
    background: linear-gradient(90deg, @grad--gray);
  }
}

.category_nav {
  .padding(bottom, medium);
  justify-content: center;
}

/**
 * products--list
 */

.products--list {
}

.product--list {
  .link--none();
  .block();
  .padding(y, giant);
  position: relative;
  background: @white;
  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgb(221,221,221);
    background: linear-gradient(90deg, @grad--gray);
  }
  &:before {
    display: none;
    top: 0;
    bottom: auto;
  }
  &:first-child {
    &:before {
      display: block;
    }
  }
  .product--list_inner {
    .row();
    .row-space(giant);
    @media @max-sm {
      flex-direction: column;
      text-align: center;
    }
  }
  .product_head_col {
    .margin(x, auto);
    .col--custom(30rem);
  }
  .product_body_col {
    .col--auto();
    flex: 1 1 0%;
    @media @max-sm {
      .margin(x, auto);
      max-width: 40rem;
    }
  }
  .product_head {
    position: relative;
    text-align: center;
  }
  .product_body {
  }
  .product_img {
    .link--none();
    cursor: pointer;
    img {
      .responsive();
      .full();
    }
  }
  .product_thumbs {
    display: none;
  }
  .product_category {
    .margin(top, medium);
    .responsive {
      max-width: 16rem !important;
      max-height: 6rem !important;
    }
  }
  .product_title {
    .h2();
    .spacing--small();
    a {
      .reset-text() !important;
      .link--none();
    }
  }
  .product_caption {
    .h7();
  }
  .product_code {
    .font-sans--regular();
    font-size: 11px;
    color: @gray--400;
    text-transform: uppercase;
    margin-bottom: 12px;
    letter-spacing: 0.05em;
  }
  .product_price {
    .spacing--small();
    .font-sans--regular();
    font-size: 2rem;
    color: @black;
  }
  .product_shortdescription {
    .spacing--medium();
    color: @gray--500;
  }
}

/**
 * products--grid
 */

.products--grid {
}

.product--grid {
  .link--none();
  .block();
  background: @white;
  color: @gray--750 !important;
  text-align: center;
  .product_head {
    position: relative;
    padding-bottom: 100%;
  }
  .product_body {
    .padding(x, 3rem);
    .padding(bottom, 5rem);
  }
  .product_img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    bottom: 1.5rem;
    right: 1.5rem;
    img {
      // object-fit
      object-fit: contain;
      object-position: center center;
      font-family: 'object-fit: contain; object-position: center center'; // polyfill
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      margin: auto;
    }
  }
  .product_category {
    height: 5rem;
    .responsive {
      max-width: 12rem !important;
      max-height: 5rem !important;
    }
  }
  .product_title {
    .h6();
    .margin(x, auto);
    max-width: 25rem;
    min-height: 36px;
    @media @max-sm {
      min-height: 72px;
    }
  }
  .product_price {
    position: absolute;
    bottom: -1.25rem;
    left: 0;
    right: 0;
  }
  .product_price_inner {
    display: inline-block;
    padding: .4rem 1.25rem;
    border-radius: 20px;
    background: @gray--300;
    .font-sans--medium();
    font-size: 1.325rem;
    color: @gray--500;
  }
}

/**
 * .slider--products
 */

.slider--products {
  .block();
  .products--grid& {
    .margin(bottom, giant);
    .slides {
      padding-bottom: 2rem;
    }
    .slides_inner {
      @media @max-sm {
        .row-space(small);
      }
    }
    .slide {
      .col(6);
      .col(4, sm);
      .col(3, lg);
    }
  }
}

/**
 * categories-banner
 */

.categories-banner {
  .link--none();
  .block();
  overflow: hidden;
  position: relative;
  padding-bottom: 43%;
  @media @min-sm {
    padding-bottom: 21.75%;
  }
  background: @gray--750;
  &.categories-banner-product {
    padding-bottom: 0;
    height: 12.5rem;
    @media @min-sm {
      padding-bottom: 0;
      height: 17.3rem;
    }
  }
}

.categories-banner_img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // object-fit
  img {
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover; object-position: center center'; // polyfill
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    margin: auto;
  }
}

.categories-banner_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  .padding(all, medium);
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .responsive {
    max-width: 19rem !important;
    max-height: 7.5rem !important;
    @media @min-md {
      max-width: 25rem !important;
      max-height: 10rem !important;
    }
  }
}

.categories-banner_logo_link {
  .link--none();
}

/**
 * categories-head
 */

.categories-head {
  .margin(bottom, big);
  text-align: center;
}

.categories-head_breadcrumbs {
  .margin(y, medium);
  text-align: center;
}

/**
 * categories-subs
 */

.categories-subs {
  .row {
    justify-content: center;
  }
}

.categories-subs_col {
  .col(12);
  @media @min-sm {
    .col(4);
  }
  @media @min-md {
    .col--auto();
    flex: 1 1 0%;
  }
  &.categories-subs_col--5 {
    @media @min-sm {
      .col(4);
      &:nth-child(4n),
      &:nth-child(5n) {
        .col(6);
        .categories-subs_item {
          @media @min-sm {
            height: 300px;
          }
        }
      }
    }
  }
  &.categories-subs_col--6plus {
    @media @min-sm {
      .col(6);
    }
    @media @min-md {
      .col(4);
    }
  }
}

.categories-subs_item {
  .link--none();
  .block();
  .text--inverse();
  position: relative;
  height: 300px;
  background: @gray--750;
  @media @min-sm {
    height: 460px;
  }
}

.categories-subs_img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // object-fit
  img {
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover; object-position: center center'; // polyfill
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    margin: auto;
  }
}

.categories-subs_text {
  display: flex;
  align-items: center;
  justify-content: center;
  .padding(all, medium);
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

/**
 * categories-lines
 */

.categories-lines {
  .row {
    justify-content: center;
  }
  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background: rgb(221,221,221);
    background: linear-gradient(90deg, @grad--gray);
  }
}

.categories-lines_col {
  .col(12);
  @media @min-sm {
    .col(4);
  }
  &.alt {
    .col(6);
    @media @min-sm {
      .col(3);
    }
    @media @min-md {
      .col(3);
    }
  }
}

.categories-lines_item {
  .link--none();
  .block();
  .margin(bottom, big);
}

.categories-lines_img {
  position: relative;
  padding-bottom: 83.9%;
  width: 100%;
  // object-fit
  img {
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover; object-position: center center'; // polyfill
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    margin: auto;
  }
}

.categories-lines_text {
  .padding(y, medium);
  .padding(x, medium);
  font-size: 1.6rem;
  color: @text;
  text-align: center;
}

.categories-lines_title {
  .spacing--small();
}
