/**
 * container
 */

.container--small {
  .margin(x, auto);
  max-width: 100rem;
}

.container--tiny {
  .margin(x, auto);
  max-width: 65rem;
}

/**
 * curator.io
 */

.curator_container {
  .block();
  background: @gray--250;
  .padding(bottom, big);
  .crt-feed {
    margin: -0.75rem;
  }
  .crt-grid-post {
    padding: 0.75rem;
  }
}

/**
 * titledesc
 */

.titledesc {
  .margin(y, giant);
  text-align: center;
}

.titledesc--small {
  .margin(y, big);
}

/**
 * banner
 */

.banner {
  .block();
  background: @gray--250;
}

/**
 * banner-support
 */

.banner-support {
  .margin(y, giant);
  text-align: center;
}

.banner-support-item {
  display: inline-block;
  .margin(y, medium);
  max-width: 23rem;
  text-align: center;
}

.banner-support-icon {
  font-size: 6.5rem;
  color: @primary;
}

.banner-support-content {
  .margin(top, small);
}

/**
 * breadcrumbs
 */

.breadcrumbs {
  display: inline-block;
  .margin-remove(x, 5px);
  > .list {
    .list-space(none);
    align-items: center;
  }
  .btn {
    .btn--nodesign();
    padding: 5px;
    .font-sans--regular();
    font-size: 10px;
    color: @gray--400;
    text-transform: uppercase;
    &:hover, &.hover, &:active, &.active {
      color: @black;
    }
    .cms-head_content & {
      color: @black;
    }
    // ellipsis
    > span {
      display: block;
      overflow: hidden;
      max-width: 20rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .icon-caret-left {
    top: -.1em;
    vertical-align: baseline;
  }
  .separator--bar {
    margin-left: 1rem;
    margin-right: 1rem;
    &:before {
      display: none;
    }
    &:after {
      content: '';
      display: inline-block;
      margin-top: -0.5rem;
      margin-bottom: -.75rem;
      height: 2.5rem;
      width: 1px;
      background: #707070;
      opacity: .2;
    }
  }
  .breadcrumbs_back {
    @media @max-sm {
      display: none;
    }
  }
}

/**
 * .toolbar
 */

.toolbar {
  .spacing--medium();
  > .list {
    justify-content: center;
    @media @min-sm {
      justify-content: space-between;
    }
  }
  @media @min-sm {
    .btn--primary {
      margin-left: auto;
    }
    .btn--primary ~ .btn--primary {
      margin-left: 0;
    }
    form {
      margin-left: auto;
    }
  }
}

/**
 * .ui.buttons
 */

.ui.buttons {
  > * {
    .margin(bottom, small);
    & + * {
      .margin(left, small);
    }
  }
}

/**
 * .ui.button
 */

.ui.button {
  .btn();
  .btn--primary();
}

/**
 * .ui.label
 */

.ui.label {
  margin: 0 !important;
  width: auto !important;
  .font-sans--regular();
  // color
  color: @primary;
  &.blue {
    color: @success;
  }
  &.green {
    color: @success;
  }
  &.red {
    color: @error;
  }
  // content
  .icon {
    &.checkmark,
    &.remove {
      .icon--left();
    }
  }
  p & {
    .margin(left, small) !important;
  }
}

/**
 * order-item
 */

.order-items {
  .margin(bottom, big);
}

.order-item {
  p {
    .margin(y, tiny);
  }
}

.order-item_title {
  .spacing--small();
  .font-sans--medium();
  font-size: 12px;
  color: @gray--750;
  line-height: 1.2;
  letter-spacing: .05em;
  text-transform: uppercase;
}

.order-item_content {
}

/**
 * totals
 */

.totals {
  .padding(top, tiny) !important;
}

.totals_item {
  .list();
  .list-space(medium);
  flex-wrap: nowrap;
  justify-content: flex-end;
  .font-sans--regular();
  font-size: 1.4rem;
  text-align: right;
  .totals_item_title {
    margin-left: auto;
  }
  .totals_item_text {
    .col--custom(9rem);
  }
  &:last-child {
    .margin(top, medium) !important;
    .padding(top, small) !important;
    border-top: @table-border-width solid @gray--250;
    .font-sans--medium();
    font-size: 1.6rem;
  }
}

/**
 * pagination
 */

.pagination {
  .list();
  .list-space(none);
  justify-content: center;
  .margin(top, big);
  .item {
    margin-left: -1px;
    &, &@{btn--design} {
      border-radius: 0 !important;
    }
    &:not(.prev):not(.next) {
      .btn();
      .btn--secondary-alt();
      padding: .75em 1em;
      font-size: 1.425rem;
    }
    &.prev, &.next {
      .btn();
      .btn--text();
      padding: 1.1em 1.5em;
      font-size: 1.225rem;
    }
  }
}

/**
 * form
 */

// required

span.required {
  &:after {
    content: '*';
    margin-left: .5rem;
  }
}

input[type="checkbox"],
input[type="radio"] {
  ~ .form-label {
    .required {
      display: none;
    }
  }
}

// validation

.form-message {
  .margin(top, small);
  font-size: 1em;
  line-height: 1.4;
  &.error {
    color: @error;
  }
}

#sylius-cart-validation-error {
  .margin(top, small);
  font-size: 1em;
  line-height: 1.4;
  color: @error;
}

.form-item {
  &.error {
    .form-input {
      border: 1px solid @error;
    }
  }
}

// .content

input[type="checkbox"],
input[type="radio"] {
  ~ .form-label {
    .content {
      .margin(left, small);
    }
  }
}

// .form-help

.form-help {
  .margin(top, small);
  .font-sans--regular();
  font-size: 12px;
  font-style: italic;
}

input[type="checkbox"],
input[type="radio"] {
  ~ .form-help {
    .margin(left, @checkradio-spacing);
  }
}

// .fields

.fields {
  .row();
  .row-space(small);
  .form-item();
  &.two {
    .field {
      .col(12);
      .col(6, sm);
    }
  }
  &.three {
    .field {
      .col(12);
      .col(4, sm);
    }
  }
}

// .sylius-flash-message

.sylius-flash-message {
  display: none
}

.sylius-flash-message-icon {
  display: block;
  float: left;
  margin-right: 15px !important;
  .icon, [class^="icon-"], [class*=" icon-"] {
    display: block;
    font-size: 18px;
  }
  & + * {
    display: block;
    overflow: hidden;
  }
}

// .sylius-message

.sylius-message {
  display: none
}

.sylius-message-icon {
  display: block;
  float: left;
  margin-right: 15px !important;
  .icon, [class^="icon-"], [class*=" icon-"] {
    display: block;
    font-size: 18px;
  }
  & + * {
    display: block;
    overflow: hidden;
  }
}

// table

.sylius-grid-table-wrapper {
  .table--scroll();
}

.table.sortable {
  th {
    a {
      .link--none();
    }
  }
}
