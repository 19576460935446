/**
 * structure
 */

.site_header {
  position: relative;
  z-index: 10;
}

.site_main {
  position: relative;
  z-index: 9;
}

.site_footer {
  position: relative;
  z-index: 9;
}

// fix overflow and sticky

.site_wrapper {
  overflow: hidden;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.site_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.site_main {
  .flex--auto();
  display: flex;
  flex-direction: column;
}

// background

body {
  &.sylius_shop_login,
  &.sylius_shop_register,
  &.sylius_shop_request_password_reset_token,
  &.sylius_shop_cart_summary,
  &.sylius_shop_cart_save,
  &.sylius_shop_checkout_address,
  &.sylius_shop_checkout_select_shipping,
  &.sylius_shop_checkout_select_payment,
  &.sylius_shop_checkout_complete {
    background: @gray--250;
  }
}
