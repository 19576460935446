//////////////////////
// decoration
//////////////////////

.link--underline {
  text-decoration: underline;
  &:hover, &.hover, &:active, &.active {
    text-decoration: underline;
  }
}

.link--none {
  text-decoration: none !important;
  &:hover, &.hover, &:active, &.active {
    text-decoration: none !important;
  }
}

//////////////////////
// text--inverse
//////////////////////

.text--inverse {
  // link color
  a:not(.btn), .btn--link {
    color: @text--inverse;
    text-decoration: underline;
    &:hover, &.hover, &:active, &.active {
      color: darken(@text--inverse, @perc--xs);
    }
  }
}

//////////////////////
// link
//////////////////////

a:not(.btn), .btn--link {
  .link--underline();
  // styles
  color: @primary;
  &:hover, &.hover, &:active, &.active {
    color: darken(@primary, @perc--xs);
  }
  // animation
  transition-property: color;
  transition-duration: @time--medium;
  transition-timing-function: @bez--out;
  &:hover, &.hover, &:active, &.active {
    transition-property: color;
    transition-duration: @time--medium;
    transition-timing-function: @bez--in;
  }
}

//////////////////////
// disabled
//////////////////////

[disabled], .disabled {
  &:not(.active) {
    opacity: .75;
  }
}

//////////////////////
// caret
//////////////////////

.caret {
  > span {
    justify-content: space-between;
    .icon-xt(@content: @icon-xt--chevron-down, @size: @icon--tiny);
    &:after {
      .icon--right();
    }
  }
  // activation
  &.active {
    > span {
      .icon-xt(@content: @icon-xt--chevron-up, @size: @icon--tiny);
      &:after {
        .anim-y-inverse.active();
      }
    }
  }
  &.out {
    > span {
      &:after {
        .anim-y.active();
      }
    }
  }
  // inverse
  &.caret--inverse {
    > span {
      .icon-xt(@content: @icon-xt--chevron-up, @size: @icon--tiny);
    }
    &.active {
      > span {
        .icon-xt(@content: @icon-xt--chevron-down, @size: @icon--tiny);
        &:after {
          .anim-y.active();
        }
      }
    }
    &.out {
      > span {
        &:after {
          .anim-y-inverse.active();
        }
      }
    }
  }
  // horizontal
  &.caret--horizontal {
    > span {
      .icon-xt(@content: @icon-xt--chevron-right, @size: @icon--tiny);
    }
    &.active {
      > span {
        .icon-xt(@content: @icon-xt--chevron-left, @size: @icon--tiny);
        &:after {
          .anim-x-inverse.active();
        }
      }
    }
    &.out {
      > span {
        &:after {
          .anim-x.active();
        }
      }
    }
    // inverse
    &.caret--inverse {
      > span {
        .icon-xt(@content: @icon-xt--chevron-left, @size: @icon--tiny, @position: before);
        &:after {
          content: '';
        }
        &:before {
          .icon--left();
        }
      }
      &.active {
        > span {
          .icon-xt(@content: @icon-xt--chevron-right, @size: @icon--tiny, @position: before);
          &:before {
            .anim-x.active();
          }
        }
      }
      &.out {
        > span {
          &:before {
            .anim-x-inverse.active();
          }
        }
      }
    }
  }
}

//////////////////////
// focus
//////////////////////

html.xt-focus:not(.xt-focus--disable) {
  // custom focus
  :focus {
    outline-color: rgb(77, 144, 254);
  }
  // checkbox and radio
  input[type="checkbox"], input[type="radio"] {
    &:focus ~ .form-label:before {
      outline-color: rgb(77, 144, 254);
      outline-offset: -2px;
      outline-style: auto;
      outline-width: 5px;
    }
  }
}
