//////////////////////
// tables
//////////////////////

@table-border-width: 2px;

.table, .table--scroll {
  .margin(y, layout) !important;
  .margin(bottom, medium);
}

// .table

.table {
  font-size: 1.3rem;
  line-height: 1.2;
  tr {
    border-bottom: @table-border-width solid @gray--250;
  }
  th,
  td:before { // data-th for .table--responsive
    .padding(all, small);
    .font-sans--medium();
    color: @gray--500;
    text-transform: uppercase;
  }
  td {
    .padding(all, small);
    font-size: 1.4rem;
  }
  th,
  td {
    &:last-child {
      text-align: right;
    }
  }
}

// .table-narrow

.table-narrow {
  th, td {
    .padding(x, layout);
    background: transparent;
  }
}

// .table-small

.table-small {
  th {
    .padding(all, small);
  }
  td {
    .padding(all, small);
    font-size: 1.2rem;
  }
}

// .table-stripe

.table-stripe {
  tbody tr:nth-of-type(odd) {
    background: @gray--250;
  }
  tbody tr:nth-of-type(even) {
    background: transparent;
  }
}

// .table-border

.table-border {
  border: @table-border-width solid @gray--250;
  th,
  td {
    border: @table-border-width solid @gray--250;
  }
}

// .table--responsive

.table--responsive {
  @media @max-sm {
    border-top: @table-border-width solid @gray--250;
    thead, th {
      display: none;
    }
    tr {
      .block();
      .padding(y, medium);
    }
    td {
      .block();
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      .padding(x, medium) !important;
      .padding(y, small);
      text-align: center !important;
      .list {
        justify-content: center;
      }
    }
    // data-th
    td:before {
      content: attr(data-th);
      text-align: center;
    }
  }
}
