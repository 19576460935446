/**
 * access
 */

.account-access {
}

.account-access-item {
  padding: 3rem;
  @media @min-sm {
    padding: 6rem;
  }
  &.small {
    padding: 3rem;
  }
  .margin(x, auto);
  .margin(bottom, medium);
  max-width: 50rem;
  border-color: @white;
  background: @white;
  color: @text;
  &:last-child {
    .margin(bottom, giant);
  }
  > .list {
    .list-space(medium);
    align-items: center;
    justify-content: center;
  }
}

/**
 * account-page
 */

.account-page {
  .padding(y, giant);
  .col--3-md {
    .order(1);
    .order(0, sm);
  }
  .col--9-md {
    .order(0);
    .order(1, sm);
  }
}

.account-page-main {
  max-width: 80rem;
}
