/**
 * slider--home
 */

.slider--home {
  .slides_inner {
    .row-space(none);
  }
  .slides_inner {
    // dragger animations
    //.transition-none() !important;
  }
  .slide {
    .col(12);
  }
}

.slider--home_item {
  .link--none();
  .block();
  background: @gray--750;
}

.slider--home_img {
  .block();
  position: relative;
  overflow: hidden;
  @media @max-md {
    height: 40rem;
    .slider--small & {
      height: 35rem;
    }
  }
  @media @min-md {
    padding-bottom: 41%;
    .slider--small & {
      padding-bottom: 37.5%;
    }
  }
  @media @min-xl {
    padding-bottom: 0 !important;
    height: 560px !important;
  }
}

.slider--home_img_inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // object-fit
  img {
    margin: auto;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center center;
  }
}

.slider--home_content {
  .text--inverse();
  @media @max-md {
    .transform-none() !important;
  }
  @media @min-md {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.slider--home_title {
  padding: 1rem 3rem;
  .font-sans--light();
  line-height: 1.2;
  text-align: center;
  @media @max-md {
    background: @gray--750;
    font-size: 2.5rem;
  }
  @media @min-md {
    background: @primary;
    font-size: 3.5rem;
    .slider--home_title_inner {
      max-width: 60rem;
    }
  }
}

/**
 * categories-home
 */

.categories-home {
}

.categories-home_col {
  .col(12);
  .col(6, sm);
  @media @min-md {
    .col--auto();
    flex: 1 1 0%;
  }
}

.categories-home_item {
  .link--none();
  .block();
  position: relative;
  height: 300px;
  background: @gray--750;
  @media @max-sm {
    display: block;
    margin: auto;
    max-width: 348px;
  }
  @media @min-sm {
    padding-bottom: 67%;
    height: auto;
  }
}

.categories-home_img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // object-fit
  img {
    object-fit: cover;
    object-position: center center;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    margin: auto;
  }
}

.categories-home_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .padding(all, medium);
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.categories-home_title {
  margin-bottom: 1rem;
  .font-sans--medium();
  color: @white;
  line-height: 1.2;
  text-align: center;
  @media @max-md {
    font-size: 2.5rem;
  }
  @media @min-md {
    font-size: 3.5rem;
  }
}

/**
 * cms
 */

.cms {
  .padding(y, giant);
}

.cms-head {
  position: relative;
  .padding(top, 3rem);
  .padding(bottom, 4rem);
  &.small {
    .padding(top, 2rem);
    .padding(bottom, 2rem);
  }
  &.gray {
    background: @gray--250;
  }
  text-align: center;
}

.cms-head_img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // object-fit
  img {
    display: block;
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover; object-position: center center'; // polyfill
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    margin: auto;
  }
}

.cms-head_content {
  position: relative;
  z-index: 2;
}

.cms-head-item {
  .margin(top, big);
  .margin(x, auto);
  max-width: 65rem;
  .font--big();
  text-align: left;
}

.cms-body {
  position: relative;
  background-color: @white;
  .padding(y, giant);
  &.medium {
    .padding(y, medium);
  }
}

.cms-body-item {
  .margin(bottom, big);
}

// .cms-2col

.cms-2col {
  .col--order-1 {
    .order(1);
    .order(0, sm);
  }
  .col--order-0 {
    .order(0);
    .order(1, sm);
  }
}

.cms-2col-main {
  max-width: 80rem;
}

/**
 * cms-assistancepolaroid
 */

.cms-assistancepolaroid {
  margin: auto;
  max-width: 75rem;
  address {
    .margin(y, medium);
    .font-sans--medium();
  }
}

/**
 * register
 */

// .register-intro

.register-intro {
  .margin(y, medium);
}

// .register-olympus

.register-olympus {
  background: @black;
  color: @white;
  text-align: center;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 7px;
    background-color: #F0C167;
    background: linear-gradient(90deg, #DEB457 0%, #FFFDBD 17%, #D6AB47 37%, #AE8225 55%, #CF9E25 75%, #B48A2D 100%);
  }
}

.register-olympus_img {
  img {
    width: 100%;
    height: auto;
  }
}

.register-olympus_content {
  .padding(y, big);
  .padding(x, medium);
}

.register-olympus_sup {
  .font-sans--regular();
  font-size: 13px;
  line-height: 1.2;
  text-transform: uppercase;
}

.register-olympus_title {
  .padding(bottom, medium);
  .spacing--medium();
  .font-sans--bold();
  font-size: 1.8rem;
  color: #F0C167;
  line-height: 1.2;
  text-transform: uppercase;
  &:after {
    content: '';
    display: block;
    position: relative;
    top: 1.5rem;
    width: 100%;
    height: 1px;
    background-color: #F0C167;
    background: linear-gradient(90deg, #DEB457 0%, #FFFDBD 17%, #D6AB47 37%, #AE8225 55%, #CF9E25 75%, #B48A2D 100%);
  }
}

.register-olympus_text {
  .spacing--small();
  .font-sans--regular();
  font-size: 1.5rem;
  line-height: 1.4;
}

.register-olympus_btn {
  .margin(top, medium);
  .btn {
    letter-spacing: 0;
    .link--none();
    color: @black;
    &@{btn--design} {
      border-color: transparent;
      background-color: #F0C167;
      background: linear-gradient(90deg, #DEB457 0%, #FFFDBD 17%, #D6AB47 37%, #AE8225 55%, #CF9E25 75%, #B48A2D 100%);
    }
    &:hover, &.hover, &:active, &.active {
      color: @black;
      &@{btn--design} {
        border-color: transparent;
        background-color: #F0C167;
        background: linear-gradient(90deg, #DEB457 0%, #FFFDBD 17%, #D6AB47 37%, #AE8225 55%, #CF9E25 75%, #B48A2D 100%);
      }
    }
  }
}

body {
  &.polyphoto_shop_product_register_olympus_assistance_program {
    background-color: @black;
    @media @min-sm {
      background-image: url('../assets/images/static/register-olympus-background.jpg');
      background-repeat: no-repeat;
      background-position: left top;
      background-attachment: fixed;
    }
    .breadcrumbs {
      .btn {
        color: #B5B5B5 !important
      }
    }
    .cms-head {
      h1 {
        color: #FFC65A;
      }
    }
    .cms-body {
      .margin(x, auto);
      width: 100%;
      max-width: 700px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 7px;
        background-color: #F0C167;
        background: linear-gradient(90deg, #DEB457 0%, #FFFDBD 17%, #D6AB47 37%, #AE8225 55%, #CF9E25 75%, #B48A2D 100%);
      }
    }
  }
}

// .register-form

.register-form {
  .margin(bottom, big);
}

.register-form_inner {
  .block();
}

/**
 * about
 */

.timeline-item {
  .margin(y, small);
}

.timeline-item-date {
  margin-bottom: -5rem;
  .font-sans--bold();
  font-size: 9.7rem;
  color: fade(@primary, 10%);
  line-height: 1.2;
  text-align: center;
}

.timeline-item-content {
  .margin(x, auto);
  max-width: 65rem;
  .font--big();
  text-align: left;
}

/**
 * sidebar
 */

.sidebar {
  .margin(y, 3rem);
  .margin(y, 5rem, sm);
  .margin(y, layout);
}

.sidebar-info {
  .font-sans--regular();
  font-size: 1.6rem;
  h4 {
    letter-spacing: 0;
    text-transform: none;
  }
}

.sidebar-contact {
  .font-sans--regular();
  font-size: 1.4rem;
  color: @primary;
  line-height: 1.4;
  text-transform: uppercase;
  > .list {
    align-items: center;
  }
  a {
    .link--none();
    .font-sans--medium();
    font-size: 1.8rem;
    color: @black;
  }
  .icon-phone {
    font-size: 4rem;
    color: @primary;
  }
}

.sidebar-hours {
  .font-sans--regular();
  font-size: 1.3rem;
  h6 {
    .margin(top, tiny) !important;
    .margin(bottom, tiny) !important;
    .margin(y, layout) !important;
    .font-sans--regular();
    font-size: 1.4rem;
    color: @primary;
    line-height: 1.4;
    text-transform: uppercase;
  }
}

.sidebar-account {
  .font-sans--regular();
  font-size: 1.4rem;
  color: @primary;
  line-height: 1.4;
  text-transform: uppercase;
  > .list-block {
    .list-space(none);
    .list_btn({
      .btn--text();
      .btn--none-x();
      padding-top: 1.4em;
      padding-bottom: 1.4em;
      border-bottom: 2px solid @gray--250;
    });
  }
}
