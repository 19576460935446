//////////////////////
// note
//////////////////////

.note {
  .margin(top, small);
  // styles
  display: block;
  width: 100%;
  .muted();
  font-size: 1.4rem;
  text-align: left;
}
