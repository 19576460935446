//////////////////////
// loader
//////////////////////

// spinner

.spinner {
  width: 25px;
  height: 25px;
  .btn & {
    width: 1em;
    height: 1em;
  }
  line-height: 0;
  svg {
    circle {
      fill: none;
      stroke-width: 30;
      transform-origin: center center;
      .btn & {
        stroke-width: 40;
      }
    }
    &:nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
      circle {
        stroke: fade(@primary, @perc--xs);
        stroke-dashoffset: 0;
      }
    }
    &:nth-child(2) {
      circle {
        stroke: @primary;
        stroke-dashoffset: 628;
        transform: rotateY(0deg) rotateZ(-90deg);
        animation: anim-spinner @time--big * 2 infinite @bez--in;
      }
    }
  }
}

@keyframes anim-spinner {
  0% {
    stroke-dashoffset: 628;
    transform: rotateY(0deg) rotateZ(-90deg);
    // fix no support transform-box: border-box;
    transform-origin: 48% 48%;
  }
  50% {
    stroke-dashoffset: 0;
    transform: rotateY(0deg) rotateZ(90deg);
    animation-timing-function: @bez--out;
    // fix no support transform-box: border-box;
    transform-origin: 48% 48%;
  }
  50.0001% {
    stroke-dashoffset: 0;
    transform: rotateY(180deg) rotateZ(90deg);
    // fix no support transform-box: border-box;
    transform-origin: center center;
  }
  100% {
    stroke-dashoffset: 628;
    transform: rotateY(180deg) rotateZ(-90deg);
    // fix no support transform-box: border-box;
    transform-origin: 46% 50%;
  }
}

// direction

.loader--x {
  .filler {
    span:nth-child(1) {
      background: fade(@black, @perc--xs);
    }
    span:nth-child(2) {
      width: 0;
      background: @black;
      animation: anim-loader--x @time--big * 2 infinite @bez--in;
    }
  }
}

.loader--y {
  .filler {
    span:nth-child(1) {
      background: fade(@black, @perc--xs);
    }
    span:nth-child(2) {
      height: 0;
      background: @black;
      animation: anim-loader--y @time--big * 2 infinite @bez--in;
    }
  }
}

@keyframes anim-loader--x {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    left: 0;
    width: 100%;
    animation-timing-function: @bez--out;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

@keyframes anim-loader--y {
  0% {
    top: 100%;
    height: 0;
  }
  50% {
    top: 0;
    height: 100%;
    animation-timing-function: @bez--out;
  }
  100% {
    top: 0;
    height: 0;
  }
}

// size

.loader--size-x,
.loader--size-top,
.loader--size-bottom {
  .filler {
    height: 3px;
  }
}

.loader--size-y,
.loader--size-left,
.loader--size-right {
  .filler {
    width: 3px;
  }
}

.loader--size-x {
  .filler {
    left: 25%;
    right: 25%;
  }
}

.loader--size-y {
  .filler {
    top: 25%;
    bottom: 25%;
  }
}

// background

.loader--background {
  background: fade(@white, @perc--xl);
}

// animations

.loader--toggle {
  display: none;
  &.active,
  &.out {
    display: block;
  }
}

.loader {
  &.active {
    opacity: 1;
    transition-property: opacity;
    transition-duration: @time--small;
    transition-timing-function: @bez--in;
    .spinner, .filler {
      opacity: 1;
      transform: scale(1);
      transition-property: opacity, transform;
      transition-duration: @time--small;
      transition-timing-function: @bez--in;
    }
  }
  &.out {
    opacity: 0;
    transition-property: opacity;
    transition-duration: @time--small;
    transition-timing-function: @bez--out;
    .spinner, .filler {
      opacity: 0;
      transform: scale(.75, .75);
      transition-property: opacity, transform;
      transition-duration: @time--small;
      transition-timing-function: @bez--out;
    }
  }
}

// mouse

.loader--mouse {
  // spinner
  .loader--spinner& {
    margin-top: -30px;
    margin-left: 5px;
  }
  // x
  .loader--x& {
    margin-top: 0px;
    margin-left: 0px;
    .filler {
      width: 60px;
    }
  }
  // animations
  .loader.out();
}

// js

.loader--js {
  // disable animations
  .spinner {
    circle {
      opacity: 1 !important;
      .trans-anim-none() !important;
    }
  }
  .filler {
    span {
      opacity: 1 !important;
      .animation-none() !important;
    }
  }
}

// .media-loaded

.media-loaded {
  .loader {
    .loader.out();
  }
}
