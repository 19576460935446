/**
 * header
 */

#purchaser-email {
  @media @max-md {
    .container();
    padding-left: 0;
    color: @white !important;
  }
  // ellipsis
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 * content
 */

.checkout {
  .margin(bottom, giant);
}

/**
 * checkout_steps
 */

.checkout_steps {
  .padding(y, big);
  .list {
    .list-space(big);
    justify-content: center;
  }
}

.checkout_step {
  .link--none();
  .font-sans--medium();
  font-size: 12px;
  color: @gray--750 !important;
  line-height: 1.2;
  letter-spacing: .05em;
  text-transform: uppercase;
  @media @max-md {
    display: none;
  }
  .icon {
    display: none;
    font-size: 8px;
  }
  &:hover {
    color: @primary--dark !important;
  }
  &.active {
    display: block;
    color: @primary--dark !important;
    .checkout_step_icon {
      background: @primary !important;
      color: @white !important;
    }
  }
  &.disabled {
    pointer-events: none;
  }
  &.completed {
    .number {
      display: none;
    }
    .icon {
      display: block;
    }
  }
}

.checkout_step_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: @white;
}

.checkout_step_text {
  display: inline-block;
}

/**
 * checkout_item
 */

.checkout_item {
  .padding(y, 3rem);
  .padding(x, medium);
  .padding(all, giant, sm);
  background: @white;
}

/**
 * checkout_section
 */

.checkout_section {
  & + .checkout_section {
    .margin(top, giant);
  }
}

/**
 * address-book-select
 */

.address-book-select {
  .block();
  .drop_outer--static();
  position: relative;
  .drop {
    > .drop_inner {
      > .drop_content {
        > .list, > .list-block {
          min-width: auto;
        }
      }
    }
    .list--drop {
      .list_btn({ text-align: center; white-space: normal; });
    }
  }
}

/**
 * checkout_shipments
 */

.checkout_shipments {
  .margin(bottom, medium);
  .form-label {
    display: none;
  }
  // .checkout_shipments_row
  .checkout_shipments_row {
    .row();
    .row-space(none);
    .form-item {
      display: flex;
      .col(12);
      .col(4, sm);
      @media @max-sm {
        margin-bottom: -1px;
      }
      @media @min-sm {
        margin-right: -1px;
      }
    }
    .form-label {
      display: block;
      padding: 0 !important;
      width: 100%;
    }
  }
  // .checkout_shipment
  .checkout_shipment {
    .block();
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid @gray--300;
    @media @max-sm {
      padding: 2rem 4rem 2rem 8rem;
    }
    @media @min-sm {
      padding: 6rem 3rem 8rem 3rem;
      height: 100%;
      text-align: center;
    }
    .checkout_shipment_icon {
      .block();
      .spacing--small();
      @media @max-sm {
        position: absolute;
        top: 2.2rem;
        left: 2rem;
        width: 3.6rem;
        text-align: center;
        img {
          max-height: 3rem;
          max-width: 4rem;
        }
      }
    }
    .checkout_shipment_title {
      .block();
      @media @min-sm {
        .margin(top, small);
      }
      .font-sans--medium();
      font-size: 1.6rem;
      color: @black;
      line-height: 1.2;
      letter-spacing: .05em;
      text-transform: uppercase;
    }
    .checkout_shipment_subtitle {
      .block();
      .margin(top, small);
      .font-sans--light();
      font-size: 1.3rem;
      color: @gray--500;
      line-height: 1.4;
      letter-spacing: 0;
      text-transform: none;
    }
    .checkout_shipment_price {
      position: absolute;
      @media @max-sm {
        top: 1.5rem;
        right: 2rem;
      }
      @media @min-sm {
        bottom: -1.25rem;
        left: 0;
        right: 0;
      }
    }
    .checkout_shipment_price_inner {
      display: inline-block;
      padding: .5rem 1.5rem;
      border-radius: 20px;
      background: @gray--300;
      .font-sans--medium();
      font-size: 1.325rem;
      color: @gray--500;
    }
  }
  input[type="radio"] {
    &:not(.unstyled) ~ .form-label {
      &:before, &:after {
        @media @max-sm {
          display: none !important;
        }
        @media @min-sm {
          position: absolute !important;
          bottom: 4rem;
          left: 50%;
          top: auto;
          margin-left: -8px;
        }
      }
    }
    &:checked ~ .form-label {
      z-index: 2;
      .checkout_shipment {
        border: 1px solid @primary;
      }
    }
  }
}

// .checkout_shipments_choose

.checkout_shipments_choose {
  margin-top: 0 !important;
  display: none;
  &.active {
    display: block;
  }
}

// .googlelocator-checkout-selected

.googlelocator-checkout-selected {
  background: @white;
  display: none;
}

.googlelocator-checkout-selected-shop {

}

.googlelocator-checkout {
  .googlelocator-checkout-selected {
    display: none;
  }
  &.selected {
    .googlelocator-checkout-selected {
      display: block;
    }
    .googlelocator_aside, .googlelocator_main {
      display: none;
    }
  }
}
