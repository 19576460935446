//////////////////////
// variables
//////////////////////

@btn--design: ~':before'; // selector for design

//////////////////////
// button
//////////////////////

.btn {
  // styles
  .font-sans--medium();
  line-height: 1.2;
  letter-spacing: .05em;
  text-transform: uppercase;
  &@{btn--design} {
    .design-setup();
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
  }
  &, &@{btn--design} {
    border-radius: 2px;
  }
  // option
  .btn--center(); // alignment
  .btn--primary(); // variant
  .btn--medium(); // size
}

// animation

.btn {
  // transition
  transition-property: color;
  transition-duration: @time--medium;
  transition-timing-function: @bez--out;
  &@{btn--design} {
    transition-property: all;
    transition-duration: @time--medium;
    transition-timing-function: @bez--out;
  }
  &:active, &.active {
    transition-property: color;
    transition-duration: @time--medium;
    transition-timing-function: @bez--in;
    &@{btn--design} {
      transition-property: all;
      transition-duration: @time--medium;
      transition-timing-function: @bez--in;
    }
  }
}

// variant

.btn--primary {
  .link--none();
  color: @text--inverse;
  &@{btn--design} {
    border-color: @primary;
    background: @primary;
  }
  &:hover, &.hover, &:active, &.active {
    color: fade(@text--inverse, @perc--xl);
    &@{btn--design} {
      border-color: darken(@primary, @perc--xs);
      background: darken(@primary, @perc--xs);
    }
  }
}

.btn--primary-dark {
  .link--none();
  color: @text--inverse;
  &@{btn--design} {
    border-color: @primary--dark;
    background: @primary--dark;
  }
  &:hover, &.hover, &:active, &.active {
    color: fade(@text--inverse, @perc--xl);
    &@{btn--design} {
      border-color: darken(@primary--dark, @perc--xs);
      background: darken(@primary--dark, @perc--xs);
    }
  }
}

.btn--secondary {
  .link--none();
  color: @primary;
  &@{btn--design} {
    border-color: lighten(@secondary, @perc--xs);
    background: lighten(@secondary, @perc--xs);
  }
  &:hover, &.hover, &:active, &.active {
    color: @primary;
    &@{btn--design} {
      border-color: @secondary;
      background: @secondary;
    }
  }
}

.btn--secondary-outline {
  .link--none();
  .font-sans--regular();
  color: @black;
  &@{btn--design} {
    border-color: @secondary;
    background: transparent;
  }
  &:hover, &.hover, &:active {
    color: @black;
    &@{btn--design} {
      border-color: @primary;
      background: transparent;
    }
  }
  &.active {
    color: @black;
    &@{btn--design} {
      border-color: @primary;
      background: transparent;
    }
  }
  &.not-available {
    color: @gray--500;
    &@{btn--design} {
      border-color: @gray--250;
      background: @gray--250;
    }
    &.active {
      color: @black;
      &@{btn--design} {
        border-color: @primary;
        background: @gray--250;
      }
    }
  }
}

.btn--secondary-alt {
  .link--none();
  color: @gray--500;
  &@{btn--design} {
    border-color: @gray--300;
    background: transparent;
  }
  &:hover, &.hover, &:active {
    color: @primary;
    &@{btn--design} {
      border-color: @gray--300;
      background: transparent;
    }
  }
   &.active {
    color: @gray--500;
    &@{btn--design} {
      border-color: @gray--300;
      background: @gray--250;
    }
  }
}

.btn--text {
  .link--none();
  color: @gray--750;
  &@{btn--design} {
    border-color: transparent;
    background: transparent;
  }
  &:hover, &.hover, &:active, &.active {
    color: @primary;
    &@{btn--design} {
      border-color: transparent;
      background: transparent;
    }
  }
}

.btn--text-alt {
  .link--none();
  .font-sans--regular();
  color: @gray--750;
  letter-spacing: 0;
  text-transform: none;
  &@{btn--design} {
    border-color: transparent;
    background: transparent;
  }
  &:hover, &.hover, &:active, &.active {
    color: @primary;
    &@{btn--design} {
      border-color: transparent;
      background: transparent;
    }
  }
}

.btn--text-inverse {
  .link--none();
  color: @white;
  &@{btn--design} {
    border-color: transparent;
    background: transparent;
  }
  &:hover, &.hover, &:active, &.active {
    color: @white;
    &@{btn--design} {
      border-color: transparent;
      background: transparent;
    }
  }
}

.btn--variant {
  .link--none();
  padding: 0.6em .8em;
  .font-sans--regular();
  font-size: 12px;
  color: @black;
  &, &@{btn--design} {
    border-radius: 4px;
  }
  &@{btn--design} {
    border-color: @gray--300;
    background: transparent;
  }
  &:hover, &.hover, &:active {
    color: @black;
    &@{btn--design} {
      border-color: @gray--300;
      background: transparent;
    }
  }
  &.active {
    color: @black;
    &@{btn--design} {
      border-color: @primary;
      background: transparent;
    }
  }
  &.not-available {
    color: @gray--500;
    &@{btn--design} {
      border-color: @gray--250;
      background: @gray--250;
    }
    &.active {
      color: @black;
      &@{btn--design} {
        border-color: @primary;
        background: @gray--250;
      }
    }
  }
  // colors
  > span {
    display: inline-block;
    > span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.color_white {
    .btn--variant_color_inner {
      background: #ffffff !important;
    }
  }
  &.color_black {
    .btn--variant_color_inner {
      background: #2E333B !important;
    }
  }
  &.color_blue {
    .btn--variant_color_inner {
      background: #3D72B8 !important;
    }
  }
  &.color_yellow {
    .btn--variant_color_inner {
      background: #FFD62C !important;
    }
  }
  &.color_pink {
    .btn--variant_color_inner {
      background: #fda79a !important;
    }
  }
  &.color_red {
    .btn--variant_color_inner {
      background: #DE553C !important;
    }
  }
  &.color_green {
    .btn--variant_color_inner {
      background: #4bb83d !important;
    }
  }
  &.color_grey {
    .btn--variant_color_inner {
      background: #7C828B !important;
    }
  }
  &.color_brown {
    .btn--variant_color_inner {
      background: #964B00 !important;
    }
  }
  &.color_light_brown {
    .btn--variant_color_inner {
      background: #AF6E4D !important;
    }
  }
  &.color_amber {
    .btn--variant_color_inner {
      background: #FFBF00 !important;
    }
  }
  &.color_champagne {
    .btn--variant_color_inner {
      background: #FAECCC !important;
    }
  }
  &.color_marbled {
    .btn--variant_color_inner {
      background: #D9D9D9 !important;
    }
  }
  &.color_charcoal {
    .btn--variant_color_inner {
      background: #050402 !important;
    }
  }

  &.color_black_black {
    .btn--variant_color_inner {
      background: #2E333B !important;
    }
  }
  &.color_black_white {
    .btn--variant_color_inner {
      background: linear-gradient(90deg, @black 0%, @black 50%, @white 50%, @white 100%) !important;
    }
  }
  &.color_black_red {
    .btn--variant_color_inner {
      background: linear-gradient(90deg, @black 0%, @black 50%, #DE553C 50%, #DE553C 100%) !important;
    }
  }
  &.color_black_blue {
    .btn--variant_color_inner {
      background: linear-gradient(90deg, @black 0%, @black 50%, #3D72B8 50%, #3D72B8 100%) !important;
    }
  }
  &.color_black_yellow {
    .btn--variant_color_inner {
      background: linear-gradient(90deg, @black 0%, @black 50%, #FFD62C 50%, #FFD62C 100%) !important;
    }
  }
  &.color_white_yellow {
    .btn--variant_color_inner {
      background: linear-gradient(90deg, @white 0%, @white 50%, #FFD62C 50%, #FFD62C 100%) !important;
    }
  }
  &.color_gray_white {
    .btn--variant_color_inner {
      background: linear-gradient(90deg, #7C828B 0%, #7C828B 50%, @white 50%, @white 100%) !important;
    }
  }
  &.color_gray_charcoal {
    .btn--variant_color_inner {
      background: linear-gradient(90deg, #7C828B 0%, #7C828B 50%, #050402 50%, #050402 100%) !important;
    }
  }

  // color outfit camera
  &.camera_outfit_color_arizona_dreaming {
    .btn--variant_color_inner {
      background: #5F3D41 !important;
    }
  }
  &.camera_outfit_color_blue_bon_boom {
    .btn--variant_color_inner {
      background: #72AFE3 !important;
    }
  }
  &.camera_outfit_color_burgundy_temptation {
    .btn--variant_color_inner {
      background: #792B32 !important;
    }
  }
  &.camera_outfit_color_camouflower {
    .btn--variant_color_inner {
      background: #D3D5EB !important;
    }
  }
  &.camera_outfit_color_hei_coffee {
    .btn--variant_color_inner {
      background: #201B22 !important;
    }
  }
  &.camera_outfit_color_into_the_blue {
    .btn--variant_color_inner {
      background: #313E66 !important;
    }
  }
  &.camera_outfit_color_olive_en_vogue {
    .btn--variant_color_inner {
      background: #4B452F !important;
    }
  }
  &.camera_outfit_color_oriental_nights {
    .btn--variant_color_inner {
      background: #473541 !important;
    }
  }
}

.btn--variant_color {
  margin-top: -.1em;
  margin-bottom: -.1em;
  margin-right: 5px;
  padding: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid @gray--300;
  background: @white;
}

.btn--variant_color_inner {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ff00ff;
}

.btn-cart {
  .btn-cart-not-available {
    display: none;
  }
  &.not-available {
    pointer-events: none;
    .btn-cart-available {
      display: none;
    }
    .btn-cart-not-available {
      display: block;
    }
    color: @gray--500;
    &@{btn--design} {
      border-color: @gray--250;
      background: @gray--250;
    }
  }
}

.btn--link {
  .btn--nodesign();
  .btn--noeffect();
  // reset
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}

.btn--sans--regular {
  .font-sans--regular();
}

// size

.btn--tiny {
  padding: .5em 1em;
  font-size: 10px;
}

.btn--small {
  padding: .6em 1.2em;
  font-size: 12.5px;
}

.btn--medium {
  padding: .6em 1.2em;
  font-size: 1.325rem; // @FIX odd rem with .025 added
}

.btn--big {
  padding: .75em 1.5em;
  font-size: 1.425rem;
}

.btn--giant {
  padding: .75em 1.5em;
  font-size: 1.6rem;
}

.btn--huge {
  padding: .75em 1.5em;
  font-size: 2rem;
}

// special

.btn--nodesign {
  &@{btn--design} {
    display: none;
  }
}

.btn--noeffect {
  &:active, &.active {
    &@{btn--design} {
      box-shadow: none;
    }
  }
}

.btn--squared {
  border-radius: 0;
  &@{btn--design} {
    border-radius: 0;
  }
}

.btn--noborder {
  &@{btn--design} {
    border: 0;
  }
}

.btn--tall {
  padding-top: .9em;
  padding-bottom: .9em;
}

.btn--short {
  padding-top: .3em;
  padding-bottom: .3em;
}

.btn--wide {
  padding-left: 1.8em;
  padding-right: 1.8em;
}

.btn--wider {
  padding-left: 3em;
  padding-right: 3em;
}

.btn--narrow {
  padding-left: .6em;
  padding-right: .6em;
}

.btn--none {
  .btn--noeffect();
  .btn--squared();
  padding: 0;
  &@{btn--design} {
    border: 0 !important;
    background: transparent !important;
  }
}

.btn--none-x {
  .btn--noeffect();
  .btn--squared();
  padding-left: 0;
  padding-right: 0;
  &@{btn--design} {
    border-left: 0 !important;
    border-right: 0 !important;
    background: transparent !important;
  }
}

.btn--none-y {
  .btn--noeffect();
  .btn--squared();
  padding-top: 0;
  padding-bottom: 0;
  &@{btn--design} {
    border-top: 0 !important;
    border-bottom: 0 !important;
    background: transparent !important;
  }
}

.btn--icon {
  border-radius: 50%;
  &@{btn--design} {
    border-radius: 50%;
  }
  padding: .8em .8em;
  letter-spacing: 0;
  line-height: 1;
}

.btn--transform {
  letter-spacing: 0;
  text-transform: none;
}

// custom

.btn--close {
  .btn--text();
  padding: 1em;
  font-size: 1.6rem;
  > span {
    .icon-xt(@content: @icon-xt--close, @size: @icon--medium);
  }
}
