//////////////////////
// variables
//////////////////////

@overlays: tiny, small, medium, big, giant, full; // if you change this change also variables names below
@overlay--tiny: @breakpoint--xs - 60;
@overlay--small: @breakpoint--sm - 60;
@overlay--medium: @breakpoint--md - 60;
@overlay--big: @breakpoint--lg - 60;
@overlay--giant: @breakpoint--xl - 60;
@overlay--full: none;

//////////////////////
// overlay
//////////////////////

.overlay {
  // styles
  .overlay_design {
    box-shadow: 0 0 10rem fade(@black, @perc--md);
  }
  .overlay_content {
    padding: 3rem;
    @media @min-sm {
      padding: 4rem;
    }
  }
  .overlay_design, .overlay_content {
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
  }
  .overlay_inner > .btn--close {
    position: absolute;
    top: 0;
    right: 0;
  }
  // option
  .overlay--white(); // variant
  .overlay--size(small); // size
}

// addons

.overlay--screen {
  // styles
  .overlay_inner > .btn--close {
    .btn--squared();
  }
  // option
  .overlay--white(); // variant
  .overlay--size(giant); // size
  .overlay--squared();
  .overlay--noborder();
}

.overlay--pos {
  // styles
  .overlay {
    .overlay--left& {
      width: 88% !important;
      max-width: 500px !important;
    }
    .overlay--right& {
      width: 88% !important;
      max-width: 500px !important;
    }
  }
  // option
  .overlay--white(); // variant
  .overlay--squared();
  .overlay--noborder();
}

.overlay--disable {
  // disable custom animation
  .overlay {
    .overlay_inner,
    .overlay_content {
      opacity: 1 !important;
      .trans-anim-none() !important;
    }
  }
}

// animation

.overlay_outer {
  animation-duration: @time--medium + @time--small; // needed for .out animation timing, put max animation time
  &.active {
    .overlay_design {
      .anim-opacity.active();
    }
    .overlay_content,
    .overlay_inner > .btn--close {
      .anim-zoom.active();
      animation-delay: @time--small;
    }
    > .backdrop {
      .backdrop.active();
    }
    // addons
    &.overlay--screen {
      .anim-opacity.active();
    }
    &.overlay--left {
      .overlay_design {
        .anim-x.active();
      }
      .overlay_content,
      .overlay_inner > .btn--close {
        .anim-zoom.active();
        animation-delay: @time--small;
      }
    }
    &.overlay--right {
      .overlay_design {
        .anim-x-inverse.active();
      }
      .overlay_content,
      .overlay_inner > .btn--close {
        .anim-zoom.active();
        animation-delay: @time--small;
      }
    }
  }
  &.out {
    .overlay_design {
      .anim-opacity.out();
      animation-delay: @time--small;
    }
    .overlay_content,
    .overlay_inner > .btn--close {
      .anim-zoom.out();
    }
    > .backdrop {
      .backdrop.out();
    }
    // addons
    &.overlay--screen {
      .anim-opacity.out();
      animation-delay: @time--small;
    }
    &.overlay--left {
      .overlay_design {
        .anim-x-inverse.out();
        animation-delay: @time--small;
      }
      .overlay_content,
      .overlay_inner > .btn--close {
        .anim-x-inverse.out();
      }
    }
    &.overlay--right {
      .overlay_design {
        .anim-x.out();
        animation-delay: @time--small;
      }
      .overlay_content,
      .overlay_inner > .btn--close {
        .anim-x.out();
      }
    }
  }
}

// variant

.overlay--black {
  .overlay_design {
    border-color: @gray--750;
    background: @gray--750;
  }
  .overlay_content {
    .text--inverse();
  }
  .overlay_inner > .btn--close {
    .btn--text();
    color: @text--inverse;
  }
}

.overlay--white {
  .overlay_design {
    border-color: @white;
    background: @white;
  }
  .overlay_content {
    color: @text;
  }
  .overlay_inner > .btn--close {
    .btn--primary();
  }
}

// special

.overlay--squared {
  .overlay_design, .overlay_content {
    border-radius: 0;
  }
}

.overlay--noborder {
  .overlay_design, .overlay_content {
    border: 0;
  }
}

.overlay--none {
  .overlay_content {
    padding: 0 !important;
  }
}
