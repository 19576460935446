//////////////////////
// variables
//////////////////////

@row-spaces: none, micro, tiny, small, medium, big, giant; // if you change this change also variables names below
@row-space--none: 0 0; // vertical horizontal
@row-space--micro: .25rem .25rem;
@row-space--tiny: .5rem .5rem;
@row-space--small: .75rem .75rem;
@row-space--medium: 1rem 1rem;
@row-space--big: 1.5rem 1.5rem;
@row-space--giant: 2rem 2rem;

//////////////////////
// row
//////////////////////

.row {
  // option
  .row-space(big); // space
  flex-wrap: wrap; // wrap
  flex-direction: row; // direction
}
