//////////////////////
// jquery-toast-plugin
// https://github.com/kamranahmedse/jquery-toast-plugin
//////////////////////

.jq-toast-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-flow: column-reverse;
  position: fixed;
  z-index: 9000 !important;
  margin: 0;
  padding: 2rem 1.5rem;
  width: 42rem;
  // fix center resize
  left: 50% !important;
  bottom: 0 !important;
  margin-left: -21rem !important;
  @media @max-sm {
    padding: 0;
    width: 100%;
    left: 0 !important;
    margin-left: 0 !important;
  }
  // animation
  animation-name: anim-fade--in;
  animation-duration: @time--big;
  animation-timing-function: @bez--in;
  animation-fill-mode: forwards;
  &.out {
    animation-name: anim-fade--out;
    animation-duration: @time--big;
    animation-timing-function: @bez--in;
    animation-fill-mode: forwards;
  }
}

.jq-toast-wrap * {
  margin: 0;
  padding: 0;
}

.jq-toast-wrap.bottom-left {
  bottom: 0;
  left: 0;
}

.jq-toast-wrap.bottom-right {
  bottom: 0;
  right: 0;
}

.jq-toast-wrap.top-left {
  top: 0;
  left: 0;
}

.jq-toast-wrap.top-right {
  top: 0;
  right: 0;
}

.jq-toast-single {
  .block;
  position: relative;
  margin-top: 1rem;
  padding: 15px;
  padding-right: 30px;
  border-radius: 0;
  border: 0;
  background: @white;
  -webkit-box-shadow: .25rem .25rem 3rem rgba(0, 0, 0, 0.12);;
  box-shadow: .25rem .25rem 3rem rgba(0, 0, 0, 0.12);;
  font-size: 13px;
  color: @white;
  line-height: 1.4;
  // animation
  .anim-y-inverse.active();
  &.toast-hide {
    .anim-y.out();
  }
}

.close-jq-toast-single {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;
  width: 23px;
  height: 23px;
  background: fade(black, 10%);
  font-size: 20px;
  line-height: 1;
  text-align: center;
  &:before {
    display: inline-block;
    .icon-xt(@content: @icon-xt--close, @size: @icon--medium);
  }
  // animation
  transition-property: background;
  transition-duration: @time--medium;
  transition-timing-function: @bez--in-out;
  &:hover {
    background: fade(black, 20%);
    transition-property: background;
    transition-duration: @time--medium;
    transition-timing-function: @bez--in-out;
  }
}

.jq-toast-loader {
  display: block;
  position: absolute;
  top: -2px;
  height: 5px;
  width: 0%;
  left: 0;
  border-radius: 5px;
  background: red;
}

.jq-toast-loaded {
  width: 100%;
}

.jq-has-icon {
}

.jq-icon-info {
  color: @white;
  background: @info;
  a {
    color: @white !important;
    text-decoration: underline !important;
  }
}

.jq-icon-error {
  color: @white;
  background: @error;
  a {
    color: @white !important;
    text-decoration: underline !important;
  }
}

.jq-icon-success {
  color: @white;
  background: @success;
  a {
    color: @white !important;
    text-decoration: underline !important;
  }
}
