//////////////////////
// scroll
//////////////////////

.scrolls--default > *,
.scroll--default {
  &:not(.scroll--once) {
    opacity: 0;
    &.fade {
      animation-name: anim-fade--in;
      animation-duration: @time--big;
      animation-timing-function: @bez--in;
      animation-fill-mode: forwards;
      &.inverse {
        animation-name: anim-fade--in-inverse;
      }
      &.initial {
        animation-name: anim-fade--in-initial;
      }
    }
    &.out {
      animation-name: anim-fade--out;
      animation-duration: @time--big;
      animation-timing-function: @bez--in;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes anim-fade--in {
  0% {
    opacity: 0;
    transform: translateY(10vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anim-fade--in-inverse {
  0% {
    opacity: 0;
    transform: translateY(-10vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anim-fade--in-initial {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anim-fade--out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
