/**
 * cart
 */
.cart {
  .margin(bottom, 4rem);
}

.cart_main {
  .block();
  .padding(y, 2rem);
  .padding(x, 3rem);
  background: @white;
}

.cart_aside {
  .block();
  position: relative;
  .padding(y, 2rem);
  .padding(x, 3rem);
  background: @white;
}

/**
 * .cart_totals
 */

.cart_totals {
  .margin(top, medium);
  .margin(bottom, medium);
  > .list-block {
    .list-space(medium);
  }
}

.cart_totals_item {
  .list();
  .list-space(tiny);
  flex-wrap: nowrap;
  justify-content: space-between;
  > * {
    line-height: 1.2;
  }
}

.cart_totals_item--big {
  .margin(top, tiny);
  .padding(top, medium);
  border-top: 2px solid @gray--250;
  .font-sans--medium();
  text-transform: uppercase;
}

#sylius-cart-items {
  input {
    display: inline-block;
    max-width: 8rem;
  }
}

/**
 * .cart_add
 */

.cart_add {
  .margin(bottom, medium);
}

/**
 * .cart_update
 */

.cart_update {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: fade(@white, 50%);
  // activation
  display: none;
  &.visible {
    display: flex;
    .anim-opacity.active();
  }
}

// @sylius-cart-update-custom

#sylius-cart-update {
  display: none;
}
