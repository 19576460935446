/**
 * footer
 */

.footer {
  .text--inverse();
  background: @gray--750;
  // h6
  h6 {
    @media @max-md {
      position: relative;
      margin: 0 !important;
      .padding(y, medium);
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, @grad--white);
      }
    }
    @media @min-md {
      .spacing--medium() !important;
    }
  }
}

/**
 * footer_top
 */

.footer_top {
  .padding(top, big);
  @media @max-md {
    text-align: center;
    .row {
      .row-space(none);
    }
  }
}

.footer_top_follow {
  @media @min-md {
    display: none;
  }
  @media @max-md {
    .order(0);
    .margin(bottom, medium);
  }
  .block;
  > .list {
    .list-space(medium);
    align-items: center;
    justify-content: center;
  }
  .btn {
    padding: 0;
    font-size: 2.5rem;
    &@{btn--design} {
      border-color: transparent !important;
      background: transparent !important;
    }
  }
}

.footer_top_text {
  .col(12);
  .col--custom(40%, md);
  .font-sans--light();
  font-size: 1.4rem;
  .footer_top_text_inner {
    @media @min-md {
      max-width: 35rem;
    }
  }
  img {
    @media @max-md {
      .margin(x, auto);
    }
    @media @min-md {
      .margin(top, 2rem);
    }
  }
}

.footer_top_links {
  .col(12);
  .col--custom(15%, md);
  @media @max-md {
    .order(1);
    padding: 0;
  }
  // content
  .font-sans--light();
  font-size: 12px;
  .footer_top_links_list {
    > .list-block {
      .list-space(small);
    }
    @media @max-md {
      .collapse--height();
      > .list-block {
        .padding(bottom, medium);
      }
    }
  }
  a {
    .link--none();
  }
  h6 {
    [class^="icon-"], [class*=" icon-"] {
      position: absolute;
      right: 10px;
      top: 25px;
      @media @min-md {
        display: none;
      }
    }
    .icon-caret-right {
      font-size: 10px;
    }
    .icon-caret-down {
      display: none;
      font-size: 6px;
    }
    &.active {
      .icon-caret-right {
        display: none;
      }
      .icon-caret-down {
        display: inline-block;
      }
    }
  }
}

/**
 * footer_bottom
 */

.footer_bottom {
  .padding(y, big);
  .padding(y, giant, md);
  @media @max-md {
    text-align: center;
    .row {
      justify-content: center !important;
    }
  }
}

.footer_bottom_copy {
  .muted();
  font-size: 10px;
}

.footer_bottom_links {
  .muted();
  font-size: 10px;
  > .list {
    .list-space(small);
    align-items: center;
    @media @max-sm {
      .list-space(tiny);
      justify-content: center;
    }
  }
  a {
    .link--none();
    text-transform: uppercase;
  }
}

/**
 * footer-newsletter
 */

.footer-newsletter {
  .padding(y, medium);
  .text--inverse();
  background: @primary;
  @media @max-md {
    .row {
      .row-space(small);
      flex-direction: column;
    }
  }
}

.footer-newsletter_icon {
  display: flex;
  align-items: center;
  font-size: 3rem;
  @media @max-md {
    display: none;
  }
}

.footer-newsletter_title {
}

.footer-newsletter_input {
  .col(12);
  .col(6, md);
  .col--custom(35%, md);
}

.footer-newsletter_follow {
  @media @max-md {
    display: none;
  }
  margin-left: auto;
  > .list {
    .list-space(small);
    align-items: center;
  }
  .btn {
    padding: 0;
    font-size: 3rem;
    &@{btn--design} {
      border-color: transparent !important;
      background: transparent !important;
    }
  }
}

#overlay--newsletter {
  .overlay_inner {
    display: flex;
    min-height: 50rem;
    @media @max-sm {
      flex-direction: column;
    }
  }
  .overlay_content {
    .col(7, sm);
    align-self: center;
  }
  .overlay--newsletter--img {
    .col(5, sm);
    align-self: stretch;
    padding: 0;
    overflow: hidden;
    // object-fit
    img {
      margin: auto;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center'; // polyfill
    }
  }
}

#overlay--promotion {
  .overlay_inner {
    /* same as .event--grid */
    max-width: 650px !important;
    @media @max-sm {
      max-width: 348px !important;
    }
  }
  .overlay_content {
    padding: 0 !important;
  }
  .event-post_body {
    padding-bottom: 0 !important;
  }
}
