//////////////////////
// variables
//////////////////////

@list-spaces: none, micro, tiny, small, medium, big, giant; // if you change this change also variables names below
@list-space--none: 0 0; // vertical horizontal
@list-space--micro: .25rem .25rem;
@list-space--tiny: .5rem .5rem;
@list-space--small: .75rem .75rem;
@list-space--medium: 1rem 1rem;
@list-space--big: 1.5rem 1.5rem;
@list-space--giant: 2rem 2rem;

//////////////////////
// list
//////////////////////

.list {
  // option
  .list-space(small); // space
  .list_btn({ .btn(); .btn--primary() }); // variant
  .list--left(); // alignment
}

.list-block {
  // option
  .list-space(small); // space
  .list_btn({ .btn(); .btn--primary() }); // variant
  .list--left(); // alignment
}

// addons

.list--drop {
  flex-wrap: nowrap;
  flex-direction: column;
  .list-space(none); // space
  .list_btn({ .btn--small(); .btn--squared(); padding-left: 2.2rem; padding-right: 2.2rem; white-space: nowrap; }); // all buttons
}

// variant

/*
.list--text {
  .list_btn({ .btn--text(); });
}
*/

// size

/*
.list--small {
  .list_btn({ .btn--small(); });
}
*/

// special

/*
.list--transform {
  .list_btn({ .btn--transform(); });
}
*/
