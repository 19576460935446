//////////////////////
// colors
//////////////////////

// gray

@white: #fff;
@black: #000000;
@gray--250: #EBEBEB;
@gray--300: #D6D6D6;
@gray--400: #7C828B;
@gray--500: #464C55;
@gray--750: #2E333B;

// text

@text: @gray--500;
@text--inverse: @white;

// color

@primary: #3D72B8;
@primary--dark: #11619B;
@secondary: @gray--300;
@info: #2E333B;
@success: #5CC764;
@error: #CD1719;

// percent

@perc--xs: 5%;
@perc--sm: 10%;
@perc--md: 25%;
@perc--lg: 40%;
@perc--xl: 80%;

// gradient

@grad--gray: fade(@gray--250, 0%) 0%, @gray--250 40%, @gray--250 60%, fade(@gray--250, 0%) 100%;
@grad--white: fade(@white, 0%) 0%, fade(@white, 10%) 40%, fade(@white, 10%) 60%, fade(@white, 0%) 100%;
@grad--primary: @primary 33%, darken(@primary, 5%) 100%;
@grad--secondary: @secondary 33%, darken(@secondary, 5%) 100%;

//////////////////////
// font
//////////////////////

@import (css) url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);

.font-sans--light() {
  font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.font-sans--regular() {
  font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-sans--medium() {
  font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.font-sans--bold() {
  font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.font-monospace() {
  font-family: monaco, Consolas, 'Lucida Console', monospace;
  font-weight: normal;
  font-style: normal;
}

//////////////////////
// document
//////////////////////

// rem

html {
  // base font size for rem
  // 100% is 16px
  // value(%) = desired(px) * 100 / 16
  font-size: 56.25%; // 9px
  @media @min-lg {
    font-size: 62.5%; // 10px
  }
}

// body

body {
  background: @white;
  .font-sans--light();
  font-size: 1.4rem;
  color: @text;
  line-height: 1.6;
}

//////////////////////
// util
//////////////////////

// margin

@margins: auto auto, none 0, micro .25rem, tiny .5rem, small 1rem, medium 2rem, big 4rem, giant 6rem, huge 12rem;

// padding

@paddings: auto auto, none 0, micro .25rem, tiny .5rem, small 1rem, medium 2rem, big 4rem, giant 6rem, huge 12rem;

// z-index

@index--base: 0;
@index--active: 2;
@index--loader: 11;
@index--sticky: 100; // @index--sticky by javascript 100 and decreses with sequential sticky
@index--top: 101;
@index--indicator: 110;
@index--backdrop: 130;
@index--drop: 150;
@index--overlay: 200;
@index--btn-close: 300;
@index--last: 301;

// order

@orders: -3, -2, -1, 0, 1, 2, 3;

//////////////////////
// grid
//////////////////////

@columns: 12;

// breakpoints

@breakpoints: xs, sm, md, lg, xl; // if you change this change also variables names below
@breakpoint--xs: 576px;
@breakpoint--sm: 768px;
@breakpoint--md: 992px;
@breakpoint--lg: 1200px;
@breakpoint--xl: 1300px;

// container

@container--max: 1340px; // max-width (none for full width)
@container--space: none 1.5rem, xs 1.5rem, sm 3rem, md 3rem, lg 3rem, xl 3rem; // container padding

// @media queries
// USAGE: @media @min-sm and @max-md {}

@min-xs: ~'(min-width:'(@breakpoint--xs)~')';
@max-xs: ~'(max-width:'(@breakpoint--xs - 1)~')';
@min-sm: ~'(min-width:'(@breakpoint--sm)~')';
@max-sm: ~'(max-width:'(@breakpoint--sm - 1)~')';
@min-md: ~'(min-width:'(@breakpoint--md)~')';
@max-md: ~'(max-width:'(@breakpoint--md - 1)~')';
@min-lg: ~'(min-width:'(@breakpoint--lg)~')';
@max-lg: ~'(max-width:'(@breakpoint--lg - 1)~')';
@min-xl: ~'(min-width:'(@breakpoint--xl)~')';
@max-xl: ~'(max-width:'(@breakpoint--xl - 1)~')';

//////////////////////
// classes generation
// false -> not generate any classes
// true -> only generate non responsive classes
// array of breakpoints -> generate non responsive classes and responsive classes for those breakpoints
//////////////////////

@classes--margin: false;
@classes--padding: false;
@classes--util: sm, md, lg;
@classes--icon: true; // only true or false
@classes--layout: true;
@classes--grid: sm, md, lg;
