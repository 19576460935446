//////////////////////
// card
//////////////////////

.card {
  // styles
  > .card_design, > .card_content {
    border-radius: .75rem;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
  }
  // option
  .card--big(); // size
  .card--secondary(); // variant
}

// addons

.card--max-height {
  > .card_content {
    overflow-y: auto;
    max-height: 25rem;
    .overflow-style();
  }
}

.card--max-width {
  width: auto;
  > .card_content {
    overflow-x: auto;
    max-width: 35rem;
    .overflow-style();
  }
}

// variant

.card--primary {
  > .card_design {
    border-color: @primary;
    background: @primary;
    background: linear-gradient(135deg, @grad--primary);
  }
  > .card_content {
    .text--inverse();
  }
}

.card--secondary {
  > .card_design {
    border-color: @secondary;
    background: @secondary;
    background: linear-gradient(135deg, @grad--secondary);
  }
  > .card_content {
    color: @text;
  }
}

.card--white {
  > .card_design {
    border-color: @white;
    background: @white;
  }
  > .card_content {
    color: @text;
  }
}

// size

.card--small {
  > .card_content {
    padding: .5em 1em;
    font-size: 1.3rem;
  }
}

.card--medium {
  > .card_content {
    padding: 1em 1.5em;
    font-size: 1.4rem;
  }
}

.card--big {
  > .card_content {
    padding: 1.5em 2em;
    font-size: 1.5rem;
  }
}

// special

.card--nodesign {
  > .card_design {
    display: none;
  }
}

.card--squared {
  > .card_design {
    border-radius: 0;
  }
}

.card--noborder {
  > .card_design {
    border: 0;
  }
}

.card--tall {
  > .card_content {
    padding-top: 1.75em;
    padding-bottom: 1.75em;
  }
}

.card--short {
  > .card_content {
    padding-top: .75em;
    padding-bottom: .75em;
  }
}

.card--wide {
  > .card_content {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.card--narrow {
  > .card_content {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.card--none {
  > .card_content {
    padding: 0;
  }
}

.card--none-x {
  > .card_content {
    padding-left: 0;
    padding-right: 0;
  }
}

.card--none-y {
  > .card_content {
    padding-top: 0;
    padding-bottom: 0;
  }
}
