//////////////////////
// variables
//////////////////////

@drop--space: 6px; // space between button and drop

//////////////////////
// drop
//////////////////////

.drop {
  // styles
  > .drop_inner {
    > .drop_design {
      box-shadow: 0 .1rem 1rem fade(@black, @perc--xs), 0 .2rem 2rem fade(@black, @perc--sm);
    }
    > .drop_content {
      > .list, > .list-block {
        .padding(y, 1.3rem); // vertical space
        min-width: 18rem;
      }
    }
    > .drop_design, > .drop_content {
      border-radius: 2px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
    }
  }
  // option
  .drop--white(); // variant
  .drop--left(); // position
  .drop--bottom(); // position
}

// animation

.drop_outer {
  &, > .drop {
    animation-duration: @time--medium + @time--small; // needed for .out animation timing, put max animation time
  }
  &.active {
    > .drop > .drop_inner {
      > .drop_design {
        .anim-opacity.active();
      }
      > .drop_content {
        .anim-zoom.active();
        animation-delay: @time--small;
      }
    }
    > .backdrop {
      .backdrop.active();
    }
  }
  &.out {
    > .drop > .drop_inner {
      > .drop_design {
        .anim-opacity.out();
        animation-delay: @time--small;
      }
      > .drop_content {
        .anim-zoom.out();
      }
    }
    > .backdrop {
      .backdrop.out();
    }
  }
}

// addons

.drop--disable {
  // disable custom animation
  > .drop > .drop_inner {
    > .drop_content {
      opacity: 1 !important;
      .trans-anim-none() !important;
    }
  }
}

.drop--max-height {
  > .drop_inner > .drop_content {
    overflow-y: auto;
    max-height: 25rem;
    .overflow-style();
  }
}

.drop--max-width {
  > .drop_inner > .drop_content {
    overflow-x: auto;
    max-width: 35rem;
    .overflow-style();
  }
}

// variant

.drop--primary {
  > .drop_inner {
    color: @text--inverse;
    > .drop_design {
      border-color: darken(@primary, @perc--xs);
      background: @primary;
    }
    > .drop_content > .list--drop {
      .list_btn({ .btn--primary() });
    }
  }
}

.drop--white {
  > .drop_inner {
    color: @text;
    > .drop_design {
      border-color: darken(@white, @perc--xs);
      background: @white;
    }
    > .drop_content > .list--drop {
      .list_btn({ .btn--text() });
    }
  }
}
