//////////////////////
// alert
//////////////////////

@alert--design: ~':before'; // selector for design

.alert {
  // styles
  .font-sans--regular();
  &@{alert--design} {
    .design-setup();
    border-radius: 0;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
  }
  // option
  .alert--secondary(); // variant
  .alert--medium(); // size
  // animation
  &.active {
    .anim-x.active();
    &.inverse {
      .anim-x-inverse.active();
    }
  }
  &.out {
    .anim-x.out();
    &.inverse {
      .anim-x-inverse.out();
    }
  }
}

// variant

.alert--primary {
  &@{alert--design} {
    border-color: @primary;
    background: @primary;
  }
  > .alert_content {
    .text--inverse();
  }
}

.alert--secondary {
  &@{alert--design} {
    border-color: @gray--250;
    background: @gray--250;
  }
  > .alert_content {
    color: @text;
  }
}

.alert--info {
  &@{alert--design} {
    border-color: @info;
    background: @info;
  }
  > .alert_content {
    .text--inverse();
  }
}

.alert--success {
  &@{alert--design} {
    border-color: @success;
    background: @success;
  }
  > .alert_content {
    .text--inverse();
  }
}

.alert--error {
  &@{alert--design} {
    border-color: @error;
    background: @error;
  }
  > .alert_content {
    .text--inverse();
  }
}

// size

.alert--small {
  > .alert_content {
    padding: .5em 1em;
    font-size: 1.3rem;
  }
}

.alert--medium {
  > .alert_content {
    padding: 1em 1.5em;
    font-size: 1.4rem;
  }
}
