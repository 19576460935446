//////////////////////
// variables
//////////////////////

@icon--tiny: .5em;
@icon--small: .75em;
@icon--medium: 1em;
@icon--big: 1.25em;
@icon--giant: 1.5em;

//////////////////////
// icon
//////////////////////

// size

.icon--tiny {
  font-size: @icon--tiny;
  img& {
    width: auto;
    height: @icon--tiny;
  }
  // make same height as 1em
  vertical-align: middle;
  margin-top: calc((1em - @icon--tiny) / 2);
  margin-bottom: calc((1em - @icon--tiny) / 2);
}

.icon--small {
  font-size: @icon--small;
  img& {
    width: auto;
    height: @icon--small;
  }
  // make same height as 1em
  vertical-align: middle;
  margin-top: calc((1em - @icon--small) / 2);
  margin-bottom: calc((1em - @icon--small) / 2);
}

.icon--medium {
  font-size: @icon--medium;
  img& {
    width: auto;
    height: @icon--medium;
  }
  // make same height as 1em
  vertical-align: middle;
  margin-top: calc((1em - @icon--medium) / 2);
  margin-bottom: calc((1em - @icon--medium) / 2);
}

.icon--big {
  font-size: @icon--big;
  img& {
    width: auto;
    height: @icon--big;
  }
  // make same height as 1em
  vertical-align: middle;
  margin-top: calc((1em - @icon--big) / 2);
  margin-bottom: calc((1em - @icon--big) / 2);
}

.icon--giant {
  font-size: @icon--giant;
  img& {
    width: auto;
    height: @icon--giant;
  }
  // make same height as 1em
  vertical-align: middle;
  margin-top: calc((1em - @icon--giant) / 2);
  margin-bottom: calc((1em - @icon--giant) / 2);
}

// space

.icon--left {
  .margin(right, 1rem);
}

.icon--right {
  .margin(left, 1rem);
}
