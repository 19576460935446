/**
 * product
 */

.product_view {
  .product_images {
    .margin(top, giant);
    .margin(bottom, giant, sm);
    .padding(top, big, sm);
    > .list {
      .list-space(big);
      flex-wrap: nowrap;
    }
  }
  .product_breadcrumbs {
    .margin(y, medium);
    text-align: center;
  }
  .product_content {
    .margin(top, medium);
    .margin(bottom, giant);
  }
  // line
  position: relative;
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgb(221,221,221);
    background: linear-gradient(90deg, @grad--gray);
  }
  // .product_images
  .product_img {
    width: auto;
  }
  .product_img_inner {
    position: relative;
    cursor: pointer;
    background: @white;
  }
  .product_category {
    position: relative;
    z-index: 2;
    margin-top: 2rem;
    text-align: center;
    .responsive {
      max-width: 25rem !important;
      max-height: 6rem !important;
    }
  }
  .product_thumbs {
    > .list-block {
      .list-space(tiny);
    }
  }
  .product_thumb {
    cursor: pointer;
    align-items: center;
    .padding(y, .75rem);
    .padding(x, .75rem);
    border: 1px solid @gray--300;
    background: @white;
    width: 6.2rem;
    min-height: 6.2rem;
    height: auto;
    &:hover, &.hover, &:active, &.active {
      border: 1px solid @primary;
    }
  }
  // .product_content
  .product_code {
    .font-sans--regular();
    font-size: 11px;
    color: @gray--400;
    text-transform: uppercase;
    margin-bottom: 12px;
    letter-spacing: 0.05em;
  }
  .product_title {
    .spacing--small();
  }
  .product_caption {
    .h7();
  }
  .product_price {
    .spacing--small();
    .font-sans--regular();
    font-size: 2.2rem;
    color: @black;
  }
  .product_shortdescription {
    .spacing--medium();
    color: @gray--500;
  }
  .product_actions {
    .margin(bottom, medium);
    // @FIX variant input value
    #sylius-product-variants,
    .form-item {
      display: none;
    }
  }
  .product_description {
    .margin(top, 3rem);
    h1, h2, h3, h4, h5, h6 {
      .h5();
      font-size: 12px;
      .margin(top, big) !important;
      .margin(bottom, medium) !important;
      .margin(y, layout) !important;
    }
    @media @min-lg {
      -webkit-column-width: 50%;
      -moz-column-width: 50%;
      column-width: 50%;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 3rem;
      -moz-column-gap: 3rem;
      column-gap: 3rem;
      /* NO it bugs title and ul when only one
      ul {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid-column;
      }
      */
    }
  }
  // responsive
  @media @max-sm {
    .product_content {
      .margin(top, 0);
    }
  }
  @media @max-md {
    .product_images {
      > .list {
        flex-direction: column;
        .product_img {
          .order(0);
        }
        .product_thumbs {
          > .list-block {
            flex-direction: row;
            justify-content: center;
          }
        }
      }
    }
  }
}

// variants

[data-gallery-change-main] {
  display: none;
  &.visible {
    display: flex;
  }
}

.product_variants {
  .block();
  .margin(bottom, 2rem);
  > .list {
    .list-space(tiny);
    .products--list & {
      @media @max-sm {
        justify-content: center;
      }
    }
  }
}

.product_variants--text {
  .block();
  .margin(top, 1rem);
  .margin(bottom, 2rem);
  > .list {
    .list-space(tiny);
  }
}

/**
 * #overlay--product_image
 */

#overlay--product_image {
  .overlay_content {
    text-align: center;
  }
}

/**
 * .product_info
 */

.product_info {
  text-align: left;
  > .list {
    flex-wrap: initial;
    align-items: center;
  }
}

.product_info_options {
  display: none;
}

.product_info_variant {
  display: none;
}
