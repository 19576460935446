//////////////////////
// variables
//////////////////////

@btn--border-width: 1px;
@input--border-width: 1px;

//////////////////////
// group
//////////////////////

.group {
  // styles
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

// variants

.group-input {
  .group--collapse();
  .form-input {
    border-color: @gray--300 !important;
  }
  .group_inner {
    > * {
      &@{btn--design} {
        border-color: @gray--300 !important;
      }
    }
  }
}
