//////////////////////
// slider
//////////////////////

// slides

.slides {
  .block();
  &.xt-autoHeight {
    // autoHeight animations
    transition-property: height;
    transition-duration: 750ms;
    transition-timing-function: @bez--in-out;
  }
}

.slides_inner {
  .row();
  .row-space(big);
  flex-wrap: nowrap;
  align-items: flex-start;
  // dragger animations
  transition-property: transform;
  transition-duration: 750ms;
  transition-timing-function: @bez--in-out;
}

// pagination

.slider_pagination {
  .list();
  .list-space(tiny);
  align-items: center;
  justify-content: center;
  .margin(top, medium);
  display: none;
  @media (hover: none) {
    display: flex;
  }
  // content
  [data-xt-pag] {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: @gray--500;
    background: @gray--500;
    &.active {
      background: transparent;
      width: 8px;
      height: 8px;
    }
    transition-property: background;
    transition-duration: @time--medium;
    transition-timing-function: @bez--in-out;
  }
}

// navigation

.slider_navigation--prev {
  left: 0;
}

.slider_navigation--next {
  right: 0;
}

.slider_navigation_btn {
  .btn();
  .btn--primary();
  .btn--huge();
  .btn--narrow();
  position: absolute;
  top: 50%;
  margin-top: -2rem;
  // animation
  transition-property: opacity, color;
  transition-duration: @time--small;
  transition-timing-function: @bez--in-out;
  opacity: 0;
  .slider:hover & {
    opacity: 1;
  }
  &:active {
    color: @primary !important;
  }
  &.active {
    opacity: 1;
  }
  &.out {
    opacity: 0;
  }
  @media (hover: none) {
    display: none;
  }
}
